<template>
  <v-row justify="center" no-gutters class="mt-3">
    <v-col cols="12" sm="9">
      <v-card outlined elevation="1" class="pa-3">
        <v-row no-gutters v-if="user.percent && user.percent != 100">
          <v-col cols="12">
            <v-alert color="teal" class="white--text font-weight-bold">
              <v-icon color="white" left>fa fa-info-circle</v-icon>
              {{textHelp(user.percent)}}
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <p class="mb-0" v-text="'Información Personal'" />
          </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="user.name"
              :rules="rules.name"
              :error-messages="errors.name"
              :label="user.type === 4 ? 'Razon social' : 'Nombre'"
              background-color="inputBg"
              dense
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            :class="$vuetify.breakpoint.xs ? 'py-0' : ''"
            v-if="user.type !== 4"
          >
            <v-text-field
              v-model="user.last_name"
              :rules="rules.last_name"
              :error-messages="errors.last_name"
              label="Apellido"
              background-color="inputBg"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xs ? 'py-0' : ''">
            <v-text-field
              background-color="inputBg"
              outlined
              dense
              v-model="birth"
              :label="user.type === 4 ? 'Fecha de Fundación' : 'Fecha de Nacimiento'"
              type="date"
            />
          </v-col>
        </v-row>
        <v-row align="baseline" dense class="mt-n5">
          <v-col cols="auto">
            <p
              class="pr-3 mb-0"
              :class="user.type !== 4 ? 'borderRight' : ''"
            >{{user.type !== 4 ? 'Documento de Identidad' : 'Ruc'}}</p>
          </v-col>
          <v-col cols="auto" v-if="user.type !== 4">
            <v-radio-group v-model="typeIdentification" row hide-details>
              <v-radio label="Cedula" on-icon="fas fa-check-circle" value="1" />
              <v-radio label="Pasaporte" on-icon="fas fa-check-circle" value="2" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider class="mb-2" v-if="user.type !== 4" />
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              :label="user.type !== 4 ? 'Identificación' : 'Ruc'"
              dense
              outlined
              background-color="inputBg"
              v-model="user.identification"
              :rules="rules.identification"
              :error-messages="errors.identification"
            />
          </v-col>
        </v-row>
        <template v-if="user.type !== 4">
          <v-row align="baseline" dense class="mt-n5">
            <v-col cols="auto">
              <p class="pr-3 mb-0 borderRight" v-text="'CONADIS (Discapacidad)'" />
            </v-col>
            <v-col cols="auto">
              <v-radio-group v-model="user.conadisFlag" row hide-details>
                <v-radio label="No Aplico" on-icon="fas fa-check-circle" :value="0" />
                <v-radio label="Aplico" on-icon="fas fa-check-circle" :value="1" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row v-if="user.conadisFlag" dense>
            <v-col cols="12">
              <v-text-field
                label="Descripción"
                dense
                outlined
                background-color="inputBg"
                v-model="user.conadisDescription"
                :rules="rules.conadisDescription"
                :error-messages="errors.conadisDescription"
              />
            </v-col>
          </v-row>
        </template>

        <v-row dense v-if="user.type !== 4">
          <v-col cols="12" sm="6">
            <v-row dense>
              <v-col cols="12">
                <p class="mb-0" v-text="'Genero'" />
              </v-col>
            </v-row>
            <v-divider />

            <v-row dense>
              <v-col>
                <v-radio-group v-model="gender" row class="mt-0">
                  <v-radio label="Masculino" on-icon="fas fa-check-circle" value="1" />
                  <v-radio label="Femenino" on-icon="fas fa-check-circle" value="2" />
                  <v-radio label="Otro" on-icon="fas fa-check-circle" value="3" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row dense>
              <v-col cols="12">
                <p class="mb-0" v-text="'Interesado en'" />
              </v-col>
            </v-row>
            <v-divider />

            <v-row dense>
              <v-col>
                <v-radio-group v-model="type" row class="mt-0">
                  <v-radio label="Publicar Trabajo" on-icon="fas fa-check-circle" value="1" />
                  <v-radio label="Buscar Trabajo" on-icon="fas fa-check-circle" value="2" />
                  <v-radio label="Ambos" on-icon="fas fa-check-circle" value="3" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <p class="mb-0" v-text="'Contactos'" />
          </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="user.phone"
              :rules="rules.phone"
              :error-messages="errors.phone"
              label="Telefono Movil"
              background-color="inputBg"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-combobox
              :items="flags"
              v-model="codeCountrySelect"
              label="Pais"
              background-color="inputBg"
              item-text="description"
              item-value="id"
              outlined
              dense
            >
              <template slot="item" slot-scope="data">
                <v-avatar class="mr-5" size="30">
                  <v-img :src="`images/flags/${data.item.image}`" />
                </v-avatar>
                {{data.item.description}}
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="user.whatsapp"
              :error-messages="errors.whatsapp"
              :rules="rules.whatsapp"
              :prefix="`(+${codeCountrySelect.code})`"
              label="WhatsApp"
              background-color="inputBg"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <p class="mb-0" v-text="'Información de Domicilio'" />
          </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-combobox
              :items="provinciasItems"
              @change="changeProvincia"
              v-model="provinciaSelect"
              item-text="provincia"
              item-value="id"
              label="Provincia"
              outlined
              background-color="inputBg"
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-combobox
              :items="cantonesItems"
              @change="changeCanton"
              v-model="cantonSelect"
              item-text="canton"
              item-value="id"
              label="Cantónn"
              outlined
              background-color="inputBg"
              dense
              :disabled="disabledCanton"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-combobox
              :items="parroquiasItems"
              v-model="parroquiaSelect"
              @change="changeParroquia"
              item-text="parroquia"
              item-value="id"
              label="Parroquia"
              outlined
              background-color="inputBg"
              dense
              :disabled="disabledParroquia"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              :rules="rules.address"
              :error-messages="errors.address"
              label="Dirección"
              background-color="inputBg"
              outlined
              dense
              ref="gmapAutocomplete"
              v-model="user.address"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <p class="mb-0" v-text="'Ubicación Exacta'" />
          </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row dense>
          <v-col cols="12">
            <GmapMap
              ref="mapRef"
              :center="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
              :clickable="true"
              :zoom="16"
              :options="mapOptions"
              @click="addMarker"
              class="heightMap400"
            >
              <GmapMarker
                icon="images/maker.png"
                :clickable="true"
                :draggable="true"
                :position="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
              />
            </GmapMap>
          </v-col>
        </v-row>
        <v-row dense justify="center" class="mt-2">
          <v-col cols="auto">
            <v-btn outlined small color="primary" @click="saveChanges">Guardar Cambios</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      codeCountrySelect: {
        id: 4,
        description: "Ecuador",
        image: "ecuador.jpg",
        code: "593",
      },
      studies: null,
      gender: null,
      type: null,
      flags: [],
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      typeIdentification: null,
      user: {
        conadisFlag: false,
        name: "",
        image: "",
      },
      latitude: null,
      longitude: null,
      disabledCanton: true,
      disabledParroquia: true,
      provinciasItems: [],
      provinciaSelect: "Seleccione Provincia",
      cantonesItems: [],
      cantonSelect: "Seleccione Canton",
      parroquiasItems: [],
      parroquiaSelect: "Seleccione Parroquia",
      parroquiaUser: 0,
      modalDatePicker: false,
      birth: null,
      errors: {
        name: "",
      },
      rules: {
        name: [(value) => !!value || "El campo usuario es Requerido"],
        last_name: [(value) => !!value || "El campo apellidos es Requerido"],
        identification: [
          (value) => !!value || "El campo identificación es Requerido",
        ],
        phone: [(value) => !!value || "El campo telefono es Requerido"],
        whatsapp: [
          (value) => !!value || "El campo whatsapp es Requerido",
          (value) =>
            /^[1-9]\d*$/.test(value) || "El numero no puede iniciar en 0",
        ],
        description: [
          (value) => !!value || "El campo descripción es Requerido",
        ],
        conadisDescription: [
          (value) => !!value || "El campo descripción CONADIS es Requerido",
        ],
        address: [(value) => !!value || "El campo dirección es Requerido"],
      },
    };
  },
  props: {
    percent: 0,
  },
  created() {
    this.formatAutocomplete();
    this.getAll();
  },
  methods: {
    textHelp(percent) {
      const value = Number(percent);
      switch (true) {
        case value >= 0 && value <= 20:
          return "Muy bien has logrado tu registro, iniciemos llenando tus datos personales.";
          break;
        case value >= 21 && value <= 60:
          return "¡Perfecto!, continuemos con tu formación, habilidades y algunas referencias.";
          break;
        case value >= 61 && value <= 79:
          return "Continuemos con habilidades y referencias segun sea su caso.";
          break;
        case value >= 80 && value <= 100:
          if (this.user.image == null) {
            return "Ya casi finalizamos coloca una imagen de perfil";
          }
          return "Para inspirar aún mas confiaza y llegar al 100 % de tu perfil te invitamos a que completes la seccion faltante la cual puede ser referencia o habilidades.";
          break;
        default:
          break;
      }
    },
    formatAutocomplete() {
      this.$gmapApiPromiseLazy().then(() => {
        let gmapAutocompletePlace = new google.maps.places.Autocomplete(
          this.$refs.gmapAutocomplete.$refs.input
        );

        gmapAutocompletePlace.setComponentRestrictions({ country: ["ec"] });

        gmapAutocompletePlace.addListener("place_changed", () => {
          let place = gmapAutocompletePlace.getPlace();

          this.user.address = place.name;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    },
    getAll() {
      axios.get(`api/provincias`).then((response) => {
        this.provinciasItems = response.data;
        axios.get(`api/codeCountries`).then((response) => {
          this.flags = response.data;
          axios.get(`api/user`).then((response) => {
            if (response.data.state === 1) {
              this.user.identification = response.data.identification;
              this.user.phone = response.data.phone;
              this.user.whatsapp = response.data.whatsapp;
              this.user.address = response.data.address;
              this.user.description = response.data.description;
              this.birth = response.data.birth;
              this.gender = response.data.gender.toString();
              this.user.state = "1";
              this.user.conadisDescription = response.data.conadisDescription;
            }

            this.user.name = response.data.name;
            this.user.type = response.data.type;
            this.type = response.data.type.toString();
            this.user.last_name = response.data.last_name;
            this.user.email = response.data.email;
            this.user.created_at = response.data.created_at;
            this.codeCountrySelect = response.data.code_country;
            this.user.image = response.data.image;
            this.user.percent = response.data.percent.total;
            this.user.conadisFlag = response.data.conadisFlag;

            this.$emit("fillData", this.user);

            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.latitude =
                  response.data.latitude === null
                    ? position.coords.latitude
                    : parseFloat(response.data.latitude);
                this.longitude =
                  response.data.longitude === null
                    ? position.coords.longitude
                    : parseFloat(response.data.longitude);
              },
              () => {
                this.latitude =
                  response.data.latitude === null
                    ? -2.89601
                    : parseFloat(response.data.latitude);
                this.longitude =
                  response.data.longitude === null
                    ? -79.00517
                    : parseFloat(response.data.longitude);
              }
            );

            if (response.data.parroquia) {
              this.parroquiaUser = response.data.parroquia.id;
              this.parroquiaSelect = response.data.parroquia.parroquia;
              this.cantonSelect = response.data.parroquia.canton.canton;
              this.provinciaSelect =
                response.data.parroquia.canton.provincia.provincia;
            }

            this.typeIdentification = response.data.type_identification.toString();
          });
        });
      });
    },
    addMarker(event) {
      this.latitude = event.latLng.lat();
      this.longitude = event.latLng.lng();
    },
    saveChanges() {
      axios
        .put(`api/user`, {
          name: this.user.name,
          last_name: this.user.last_name,
          type_identification:
            this.user.type === 4 ? 2 : this.typeIdentification,
          identification: this.user.identification,
          birth: this.birth,
          studies: this.studies,
          gender: this.gender,
          type: this.type,
          phone: this.user.phone,
          code_country_id: this.codeCountrySelect.id,
          whatsapp: this.user.whatsapp,
          email: this.user.email,
          address: this.user.address,
          description: this.user.description,
          latitude: this.latitude,
          longitude: this.longitude,
          parroquia_id: this.parroquiaUser,
          conadisDescription: this.user.conadisDescription,
          conadisFlag: this.user.conadisFlag,
        })
        .then((response) => {
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Hemos registrado tu nueva informacion con exito.",
            "success"
          );

          this.$store.dispatch("setAddress", {
            latitude: this.latitude,
            longitude: this.longitude,
            parroquia_id: this.parroquiaUser,
            address: this.user.address,
          });

          this.getAll();

          this.errors = [];
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              this.$swal("Error", "Verifique Datos ingreados", "error");
              break;
          }
        });
    },
    changeProvincia(e) {
      axios.get(`api/canton/${e.id}`).then((response) => {
        this.disabledCanton = false;
        this.cantonesItems = response.data;
      });
    },
    changeCanton(e) {
      axios.get(`api/parroquia/${e.id}`).then((response) => {
        this.disabledParroquia = false;
        this.latitude = e.latitud;
        this.longitude = e.longitud;
        this.parroquiasItems = response.data;
      });
    },
    changeParroquia(e) {
      this.parroquiaUser = e.id;
    },
  },
  watch: {
    modalDatePicker(val) {
      val && setTimeout(() => (this.$refs.birthPicker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.id,
    }),
    phoneCopy() {
      return this.user.phone;
    },
  },
  watch: {
    phoneCopy() {
      this.user.whatsapp = this.user.phone.slice(1);
    },
  },
};
</script>
