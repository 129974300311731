<template>
  <v-container grid-list-xs fluid class="pt-0">
    <v-row justify="center" no-gutters class="mt-3">
      <v-col cols="12" md="4" lg="3">
        <v-card
          v-if="contactList"
          flat
          outlined
          style="border-top-right-radius: 0; border-bottom-right-radius:0;"
        >
          <v-card-title class="px-2 py-2 titleChat">
            <v-row justify="center" no-gutters>
              <v-col cols="auto">
                <p class="ma-0 primary--text">Conversaciones</p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-container grid-list-xs ref="chatContainer" class="overflow-y-auto scroll-y pa-0 ma0">
            <v-card-text
              class="pa-0"
              :style="`max-height:${this.$vuetify.breakpoint.height-163}px; height:${this.$vuetify.breakpoint.height-163}px`"
            >
              <v-list three-line class="pa-0 ma-0">
                <div v-for="(contact, index) in contacts" :key="index">
                  <v-list-item @click="getMessages(contact)">
                    <v-list-item-avatar width="50" height="50">
                      <v-img
                        :src="contacts == null ? 'images/incognita.jpg' : `storage/avatar/${contact.contact.id}/${contact.contact.image}`"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        :class="typeColor(contact.traby.traby_control, contact.contact.id)"
                        v-html="contacts == null ? '' : `${contact.contact.name} ${contact.contact.last_name} (${contact.traby.title})`"
                      />
                      <v-list-item-subtitle>
                        <v-icon
                          left
                          style="font-size:14px"
                          :class="contact.state != 0 ? 'green--text' : 'gray--text'"
                        >fas fa-circle</v-icon>
                        {{ contact.state != 0 ? 'En Linea' : 'Desconectado' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-badge
                        bottom
                        color="error"
                        left
                        overlap
                        class="mt-8"
                        :content="20"
                        :value="false"
                      />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider inset />
                </div>
              </v-list>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" lg="6">
        <v-card
          flat
          outlined
          v-if="!conversationSelect.id && this.$vuetify.breakpoint.width > 959"
          :style="contactList ? 'border-top-left-radius: 0; border-bottom-left-radius:0; border-left:0px' : ''"
        >
          <v-container
            class="pa-0"
            :style="`max-height:${this.$vuetify.breakpoint.height-114}px; height:${this.$vuetify.breakpoint.height-114}px`"
          >
            <v-row no-gutters style="height:100%" justify="center" align="center">
              <v-col cols="auto">
                <v-img width="320" height="160" contain src="images/logo.svg" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card
          v-if="conversationSelect.id"
          flat
          outlined
          :style="contactList ? 'border-top-left-radius: 0; border-bottom-left-radius:0; border-left:0px' : ''"
        >
          <v-card-title class="px-2 py-2 d-flex justify-space-between titleChat">
            <v-btn icon color="primary" small @click="conversationSelect.id = null">
              <v-icon color="teal" style="font-size:18px">fas fa-arrow-left</v-icon>
            </v-btn>
            <p class="ma-0 pa-0 text-truncate d-flex align-center primary--text">
              <v-avatar size="28" color="primary" class="mr-2">
                <v-img :src="conversationSelect.image == null ? '' : conversationSelect.image" />
              </v-avatar>
              {{conversationSelect.title}} {{statusTraby()}}
            </p>
            <v-btn
              v-if="conversationSelect.give === id && conversationSelect.status === 0"
              @click="giveTraby()"
              color="teal"
              small
              depressed
              elevation="1"
              class="white--text"
            >Dar Traby</v-btn>
          </v-card-title>
          <v-divider />
          <v-container
            grid-list-xs
            ref="chatContainer"
            class="overflow-y-auto scroll-y pa-0 ma0 backgroundChat"
          >
            <v-card-text
              class="pa-0 px-2"
              :style="`max-height:${this.$vuetify.breakpoint.height-220}px; height:${this.$vuetify.breakpoint.height-220}px`"
            >
              <div v-for="(item, index) in messages" :key="index">
                <v-row no-gutters class="py-2" justify="center">
                  <v-col cols="auto">
                    <v-card
                      flat
                      class="shadowBurbleChat"
                      style="border-radius:10px; background-color: #f8eec7"
                    >
                      <v-card-text class="py-1 px-2" width="150">
                        <v-row no-gutters justify="center">
                          <v-col cols="auto">
                            <p class="ma-0 primary--text text-overline">{{dateMessage(index)}}</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="py-2"
                  v-for="(item, index) in item"
                  :key="index"
                  :justify="item.user.id != id ? 'start' : 'end'"
                >
                  <v-col cols="12" sm="7">
                    <v-card
                      flat
                      class="shadowBurbleChat"
                      style="border-radius:10px;"
                      :class="item.user.id != id ? 'white' : 'sendBurbleChat'"
                    >
                      <v-card-text class="pt-2 px-2 pb-0">
                        <v-row no-gutters>
                          <v-col class="pa-0">
                            <p class="ma-0 primary--text text-body-2">{{item.description}}</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions
                        class="ma-0 px-2 pb-1 pt-1"
                        :class="item.user.id != id ? 'white' : 'sendBurbleChat'"
                      >
                        <v-row no-gutters justify="space-between" align="baseline">
                          <v-col cols="auto">
                            <p
                              style="font-family: sand-serif"
                              class="font-weight-bold primary--text ma-0 text-caption"
                            >Oferta: $ {{item.price}}</p>
                          </v-col>
                          <v-col cols="auto">
                            <p
                              style="font-family: sand-serif"
                              class="primary--text ma-0 text-caption"
                            >{{timeMessage(item.created_at)}}</p>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-container>
          <v-divider />
          <v-card-actions class="titleChat ma-0 pa-0">
            <v-row v-if="showMessageControlsFields()" dense class="py-2 px-2" justify="center">
              <v-col
                cols="12"
                sm="3"
                v-if="this.conversationSelect.status === 0"
                :class="this.$vuetify.breakpoint.width < 600  ? 'mb-2' : ''"
              >
                <v-text-field
                  style="font-family:Roboto; display:flex;"
                  class="ma-0 pa-0"
                  v-model="lastPriceConversation"
                  @keyup.enter="sendMessage"
                  rounded
                  hide-details
                  placeholder="Oferta"
                  background-color="white"
                  prefix="Oferta: $"
                />
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field
                  :disabled="this.conversationSelect.status === 4"
                  class="ma-0 pa-0"
                  v-model="descriptionMessage"
                  @keyup.enter="sendMessage"
                  rounded
                  hide-details
                  :placeholder="this.conversationSelect.status === 4 ? 'Traby Finalizado' : 'Mensaje'"
                  background-color="white"
                  :append-icon="this.conversationSelect.status === 4 ? '' : 'far fa-paper-plane'"
                />
              </v-col>
            </v-row>
            <v-row v-else dense class="py-2 px-2" justify="center">
              <v-col cols="12" sm="9">
                <v-text-field
                  class="ma-0 pa-0"
                  rounded
                  hide-details
                  placeholder="Este traby fue asignado a otro traber"
                  background-color="white"
                  disabled
                />
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      messages: [],
      message: null,
      contactActive: null,
      contacts: [],
      conversationSelect: {
        contactId: null,
        id: null,
        give: 0,
        recive: 0,
        title: null,
        image: null,
        status: false,
      },
      conversation: 0,
      lastPriceConversation: null,
      conversationGiveTraby: null,
      descriptionMessage: null,
      conversationTitle: null,
      conversationImage: null,
      conversationStatus: null,
    };
  },
  created() {
    this.fetchUsersChat();
  },
  mounted() {
    Echo.join("ChatChannel")
      .here((users) => {
        const idUsers = users.map((item) => item.id);
        this.contacts.forEach((contact, index) => {
          if (idUsers.includes(contact["contact"].id))
            this.contacts[index]["state"] = 1;
        });
      })
      .joining((user) => {
        this.contacts.forEach((item, index) => {
          if (user.id == item["contact"].id) this.contacts[index]["state"] = 1;
        });
      })
      .leaving((user) => {
        this.contacts.forEach((item, index) => {
          if (user.id == item["contact"].id) this.contacts[index]["state"] = 0;
        });
      })
      .listen("ChatEvent", (e) => {
        this.addMessage(e.message);
      });
  },
  destroyed() {
    Echo.leave("ChatChannel");
  },
  methods: {
    fetchUsersChat() {
      axios.get("api/contactsMessages").then((response) => {
        this.contacts = response.data;
      });
    },
    addMessage(message) {
      let date = moment().format("L");

      if (this.messages[date]) {
        this.messages[date].push(message);
      } else {
        Object.assign(this.messages, { [date]: [message] });
      }
      this.chatScrollBottom();
    },
    timeMessage(time) {
      return moment(time).format("h:mm a");
    },
    dateMessage(time) {
      return moment(time, "DD/MM/YYYY").format("LL");
    },
    getMessages(conversation) {
      axios
        .get(`api/messages/${conversation.conversation}`)
        .then((response) => {
          this.messages = response.data;

          const onlyMessages = Object.values(this.messages).flat();
          this.lastPriceConversation =
            onlyMessages[onlyMessages.length - 1].price;

          this.conversationSelect.contactId = conversation.contact.id;
          this.conversationSelect.id = conversation.conversation;
          this.conversationSelect.title = `${conversation.contact.name} ${conversation.contact.last_name}`;
          this.conversationSelect.image = `storage/avatar/${conversation.contact.id}/${conversation.contact.image}`;
          this.conversationSelect.give = conversation.traby.give;
          this.conversationSelect.recive = conversation.traby.traby_control
            ? conversation.traby.traby_control.recive
            : 0;
          this.conversationSelect.status = conversation.traby.traby_control
            ? conversation.traby.traby_control.status
            : 0;
        });
    },
    sendMessage() {
      if (
        this.lastPriceConversation == null ||
        this.lastPriceConversation == "" ||
        this.descriptionMessage == null ||
        this.descriptionMessage == ""
      )
        return;

      axios
        .post(`api/messageConversation`, {
          conversation_id: this.conversationSelect.id,
          price: this.lastPriceConversation,
          description: this.descriptionMessage,
        })
        .then((response) => {
          this.descriptionMessage = null;
          this.addMessage(response.data);
        });
    },
    chatScrollBottom() {
      this.$nextTick(() => {
        let chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.clientHeight + 200;
      });
    },
    giveTraby() {
      axios
        .post("api/giveTraby", {
          conversation: this.conversationSelect.id,
        })
        .then((response) => {
          this.fetchUsersChat();
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Pronto recibiras ayuda de mano de obra calificada",
            "success"
          );
        });
    },
    typeColor(trabyControl, contact) {
      if (trabyControl == null) return;

      if (trabyControl.recive == this.id) return "teal--text";

      if (trabyControl.give == this.id && contact == trabyControl.recive)
        return "warning--text ";
    },
    statusTraby() {
      if (this.conversationSelect.status === 0) return "";
      if (this.conversationSelect.recive == this.id) return "(Traby Asignado)";
      if (
        this.conversationSelect.give == this.id &&
        this.conversationSelect.contactId == this.conversationSelect.recive
      )
        return "(Asignaste Traby)";
    },
    showMessageControlsFields() {
      if (this.conversationSelect.status === 0) return true;
      if (
        this.conversationSelect.recive == this.id ||
        this.conversationSelect.give == this.id
      )
        return true;
      return false;
    },
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.id,
    }),
    contactList() {
      if (this.$vuetify.breakpoint.width > 959) return true;
      if (!this.conversationSelect.id) return true;
    },
  },
  watch: {
    messages(messages) {
      this.chatScrollBottom();
    },
    conversation(conversation) {
      this.chatScrollBottom();
    },
  },
};
</script>
<style>
.priceMessage .v-badge__badge {
  height: 45px !important;
  border-radius: 50%;
}
</style>