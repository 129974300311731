<template>
  <v-app >
    <Appbar />
    <v-main class="backgroundApp" dark>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Appbar from "@/js/components/Appbar";
export default {
  data() {
    return {};
  },
  created() {
    this.dataUser();
  },
  methods: {
    dataUser() {
      axios.get(`api/user/data`).then(response => {
        this.$store.dispatch("userAuth", response.data);
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    axios
      .get(`api/verify`)
      .then(response => {
        next();
      })
      .catch(error => {
        localStorage.clear();
        next("/login");
      });
  },
  components: {
    Appbar
  }
};
</script>
