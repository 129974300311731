import Vue from "vue";
import VueRouter from "vue-router";
import Login from "./views/Login/Login";
import FormSignInCompany from "./views/Login/FormSignInCompany";
import Application from "./views/Application/Application";
import Home from "./views/Application/Home/Home";
import Profile from "./views/Application/Profile/Profile";
import Account from "./views/Application/Account/Account";
import GiveTraby from "./views/Application/GiveTraby/GiveTraby";
import SearchTraby from "./views/Application/SearchTraby/SearchTraby";
import SearchTraber from "./views/Application/SearchTraber/SearchTraber";
import Message from "./views/Application/Message/Message";
import Calendar from "./views/Application/Calendar/Calendar";
import TrabyControl from "./views/Application/TrabyControl/TrabyControl";
import GlobalSearch from "./views/Application/GlobalSearch/GlobalSearch";

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {
            path: "/login",
            name: "Login",
            component: Login
        },
        {
            path: "/registerCompany",
            name: "registerCompany",
            component: FormSignInCompany
        },
        {
            path: "/",
            component: Application,
            children: [
                {
                    path: "/",
                    name: "Home",
                    component: Home
                },
                {
                    path: "/profile/:id",
                    name: "Profile",
                    component: Profile
                },
                {
                    path: "/account",
                    name: "Account",
                    component: Account
                },
                {
                    path: "/give",
                    name: "GiveTraby",
                    component: GiveTraby
                },
                {
                    path: "/search",
                    name: "SearchTraby",
                    component: SearchTraby
                },
                {
                    path: "/searchTraber",
                    name: "SearchTraber",
                    component: SearchTraber
                },
                {
                    path: "/message",
                    name: "Message",
                    component: Message
                },
                {
                    path: "/calendar",
                    name: "Calendar",
                    component: Calendar
                },
                {
                    path: "/trabyControl",
                    name: "TrabyControl",
                    component: TrabyControl
                },
                {
                    path: "/globalSearch",
                    name: "GlobalSearch",
                    component: GlobalSearch
                }
            ]
        }
    ]
});
