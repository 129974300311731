import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import search from "./modules/search";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        search
    },
    plugins: [createPersistedState({ storage: window.sessionStorage })]
});
