<template>
  <v-container grid-list-xs fluid grid>
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-row class="border borderTop" align="center">
          <v-col cols="auto">
            <v-btn color="primary" small @click="dialogFilter = true" outlined>
              <v-icon color="teal" size="16" left>fas fa-search</v-icon>Buscar Traby
            </v-btn>
          </v-col>

          <v-spacer />
          <v-col cols="auto" v-if="$vuetify.breakpoint.width > 765">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Mostrar Mapa"
              v-model="visibleMap"
              color="primary"
              on-icon="far fa-check-square"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-responsive
            class="overflow-y-auto"
            :height="`${this.$vuetify.breakpoint.height-230}px`"
            width="50%"
          >
            <v-col cols="12" sm="12" class="py-0 px1">
              <v-row>
                <v-col cols="12" class="border" v-if="trabiesList.length != 0">
                  <p class="mb-0">
                    Tenemos
                    <span>{{totalTrabies}}</span>
                    trabys ideal{{totalTrabies > 1 ? 'es' : ''}} para ti
                  </p>
                </v-col>
                <v-col cols="12" v-if="trabiesList.length == 0">
                  <v-row no-gutters>
                    <v-col>
                      <p class="mb-0">
                        <span class>No hay resultados de Trabys</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <p
                        class="text-justify"
                      >No se han encontrado trabys para ti, mejora tu búsqueda cambiando las fechas, eliminando los filtros o ampliando la zona en el mapa.</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  :sm="visibleMap ? 6 : 3"
                  v-for="item in trabiesList"
                  :key="item.id"
                >
                  <v-card outlined elevation="1">
                    <v-list-item @click="goProfile(item.give)" class="px-2">
                      <v-list-item-avatar class="mr-2 my-1">
                        <v-img
                          :src="item.user.image ? `storage/avatar/${item.user.id}/${item.user.image}` : 'images/incognita.jpg'"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="`${item.user.name} ${item.user.last_name}`"
                          class="text-subtitle-2 font-weight-regular"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <div v-if="item.photos_traby.length > 0">
                      <v-carousel
                        height="150"
                        hide-delimiter-background
                        show-arrows-on-hover
                        next-icon="fas fa-caret-right"
                        prev-icon="fas fa-caret-left"
                      >
                        <v-carousel-item v-for="(slide, i) in item.photos_traby" :key="i">
                          <v-img
                            class="align-end"
                            :src="`storage/trabies/${item.id}/${item.photos_traby[i].image}`"
                            height="150"
                          />
                        </v-carousel-item>
                      </v-carousel>
                    </div>

                    <div v-else>
                      <v-sheet height="150" tile color="grayAlt">
                        <v-row justify="center" align="end" style="height:75px" no-gutters>
                          <v-col cols="auto">
                            <v-icon color="primary" size="35">far fa-images</v-icon>
                          </v-col>
                        </v-row>
                        <v-row
                          justify="center"
                          align="start"
                          style="height:75px"
                          class="mt-3"
                          no-gutters
                        >
                          <v-col cols="auto">
                            <p class="ma-0 text-h6 font-weight-bold primary--text">SIN IMAGEN</p>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </div>

                    <v-divider />

                    <v-card-text class="px-3 py-2">
                      <v-row no-gutters>
                        <v-col>
                          <p
                            class="mb-0 text-justify text-truncate"
                          >{{item.title}} {{item.intern ? '(Practicante)' : ''}}</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <p class="mb-2 text-justify text-truncate">{{item.description}}</p>
                        </v-col>
                      </v-row>
                      <v-divider color="primary" />
                      <v-row no-gutters>
                        <v-col class="pa-0">
                          <v-row no-gutters>
                            <v-col>
                              <p
                                class="text-caption mb-0 mt-2 text-truncate"
                              >{{item.trabiesable.description}}</p>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col>
                              <p
                                class="mb-2 text-truncate text-capitalize"
                              >{{dayTraby(item.time_need)}}</p>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="auto" class="py-0 px-2 d-flex align-center warning">
                          <span class="mr-1">$</span>
                          <span>{{item.price_init}}</span>
                        </v-col>
                      </v-row>
                      <v-divider color="primary" />
                    </v-card-text>

                    <v-card-actions class="px-3 pt-0 pb-2">
                      <v-row justify="space-between" no-gutters>
                        <v-col>
                          <v-btn
                            color="primary"
                            text
                            small
                            @click="contactTraby(item.id)"
                            class="pl-0"
                          >
                            <v-icon color="teal" size="20" class="pr-2">far fa-comment-alt</v-icon>Contactar
                          </v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col class="d-flex justify-end">
                          <v-btn color="primary" icon small @click="showInfoTraby(item.id)">
                            <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                          </v-btn>

                          <v-btn color="primary" icon small>
                            <v-icon color="teal" size="20">fa fa-share-alt</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-responsive>
          <v-col cols="12" sm="6" v-if="visibleMap && $vuetify.breakpoint.width > 765" class="pa-0">
            <GmapMap
              ref="mapRef"
              @idle="boundsChanged"
              :center="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
              :clickable="true"
              :zoom="15"
              :options="mapOptions"
              :style="`height: ${this.$vuetify.breakpoint.height-230}px`"
            >
              <div v-for="item in trabiesList" :key="item.id">
                <GmapMarker
                  icon="images/maker.png"
                  :title="item.trabiesable.description"
                  :position="{ lat: Number(item.latitude_init), lng: Number(item.longitude_init) }"
                />
              </div>
            </GmapMap>
          </v-col>
        </v-row>

        <v-row justify="center" class="border borderTop" no-gutters>
          <v-col cols="auto" class="py-2">
            <v-pagination
              :length="pagination.total"
              v-model="pagination.current"
              next-icon="fa fa-chevron-right"
              prev-icon="fa fa-chevron-left"
              @input="onPageChange"
              circle
              total-visible="7"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTraby" persistent max-width="400px">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text title ma-0">Detalles del Traby</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <GmapMap
                ref="mapRef"
                :center="{ lat: Number(traby.latitude_init), lng: Number(traby.longitude_init) }"
                :clickable="true"
                :zoom="16"
                :options="mapOptions"
                class="heightMap150"
              >
                <GmapMarker
                  icon="images/maker.png"
                  :clickable="true"
                  :draggable="true"
                  :position="{ lat: Number(traby.latitude_init), lng: Number(traby.longitude_init) }"
                />
              </GmapMap>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-subtitle-2 ma-0">Descripcion:</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border">
            <v-col>
              <p class="text-justify text-body-2 mb-1">{{traby.description}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p
                class="mb-1 text-subtitle-2"
              >{{traby.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</p>
            </v-col>
            <v-col cols="6">
              <p
                class="text-body-2 mb-1 text-right"
              >{{traby.trabiesable ? traby.trabiesable.description : ''}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline" justify="space-between">
            <v-col cols="auto">
              <p class="mb-1 text-subtitle-2">Fecha y Hora:</p>
            </v-col>
            <v-col cols="auto">
              <p class="text-body-2 mb-1 text-right">{{dayTraby(traby.time_need)}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p class="mb-1 text-subtitle-2">Oferta:</p>
            </v-col>
            <v-col cols="6">
              <p class="text-body-2 mb-1 text-right">$ {{traby.price_init}}</p>
            </v-col>
          </v-row>
          <div v-if="traby.photos_traby.length > 0">
            <v-row no-gutters class>
              <v-col cols="12">
                <p class="text-subtitle-2 my-2 text-center">Referencia Visual</p>
              </v-col>
            </v-row>
            <v-row no-gutters justify="space-between">
              <v-col
                cols="3"
                class="d-flex justify-center mx-1 mb-3"
                v-for="(image,i) in traby.photos_traby"
                :key="i"
              >
                <v-img
                  height="50px"
                  width="50px"
                  :src="`storage/trabies/${traby.id}/${image.image}`"
                />
              </v-col>
            </v-row>
          </div>
          <v-row no-gutters :class="traby.photos_traby.length == 0 ? 'mt-2' : ''">
            <v-col>
              <v-btn
                outlined
                small
                block
                color="primary"
                @click="dialogTraby = false"
              >Regresar a busqueda</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTrabyMessage" persistent max-width="400px">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <v-avatar size="36px">
                <v-img
                  :src="traby.user != undefined ? `storage/avatar/${traby.user.id}/${traby.user.image}` :''"
                />
              </v-avatar>
            </v-col>
            <v-col cols="auto">
              <p
                class="ml-3 teal--text text-h6 ma-0"
              >{{traby.user != undefined ? traby.user.name : ''}} {{traby.user != undefined ? traby.user.last_name : ''}}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-subtitle-2 ma-0">Descripcion:</p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <p class="text-justify text-body-2 mb-0">{{traby.description}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                hide-details
                label="Mensaje"
                color="primary"
                background-color="inputBg"
                outlined
                no-resize
                v-model="message"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="counterOffer"
                hide-details
                label="Oferta"
                number
                color="primary"
                background-color="inputBg"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mt-3" no-gutters>
            <v-col cols="5">
              <v-btn
                outlined
                small
                block
                color="primary"
                @click="dialogTrabyMessage = false"
              >Regresar</v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn outlined small block color="primary" @click="sendMessage">Contactar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFilter" persistent max-width="400px" transition="dialog-transition">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text text-h6 ma-0">Buscar Traby</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="py-2">
          <v-row no-gutters>
            <v-col>
              <p class="mb-0">Tipo de trabajo por:</p>
            </v-col>
          </v-row>
          <v-divider />
          <v-row no-gutters class="mt-n3">
            <v-col>
              <v-radio-group row v-model="giveType" hide-details>
                <v-radio label="Habilidades" on-icon="fas fa-check-circle" :value="1" />
                <v-radio label="Titulo Profesional" on-icon="fas fa-check-circle" :value="2" />
                <v-radio label="Practicante" on-icon="fas fa-check-circle" :value="3" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-if="giveType === 1"
                :items="categoryItems"
                v-model="categorySelect"
                hide-details
                item-text="description"
                item-value="id"
                label="Categoria"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />

              <v-combobox
                v-if="giveType >= 2"
                :items="careeresItems"
                v-model="careeresSelect"
                hide-details
                item-text="description"
                item-value="id"
                label="Profesion"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-menu
                ref="dialogDatePicker"
                v-model="modalDatePicker"
                :return-value.sync="date"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRangeText"
                    dense
                    hide-details
                    label="Fecha del Traby"
                    readonly
                    outlined
                    color="primary"
                    background-color="inputBg"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="date" range width="300px">
                  <v-row justify="space-around" no-gutters class="mt-n8">
                    <v-col cols="auto">
                      <v-btn
                        depressed
                        color="primary"
                        outlined
                        @click="modalDatePicker = false"
                        small
                      >Regresar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        outlined
                        color="primary"
                        @click="$refs.dialogDatePicker.save(date)"
                        small
                      >Aceptar</v-btn>
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <p class="mb-0">Rango de precio</p>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="slider[0]"
                outlined
                dense
                color="primary"
                background-color="inputBg"
                label="Minimo"
                hide-details
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="slider[1]"
                outlined
                dense
                color="primary"
                background-color="inputBg"
                label="Maximo"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row no-gutters justify="space-around">
            <v-col cols="auto">
              <v-btn color="primary" small outlined @click="cleanFilter">Cancelar</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" small outlined @click="activeFilter">Buscar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
moment.locale("es");
export default {
  data() {
    return {
      giveType: null,
      dialogFilter: false,
      dialogTraby: false,
      dialogTrabyMessage: false,
      visibleMap: true,
      latitude: 0,
      longitude: 0,
      mapOptionsResume: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        disableDefaultUI: true,
        draggable: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      traby: {
        description: null,
        time_need: null,
        latitude_init: null,
        longitude_init: null,
        category: {
          description: null,
        },
        price_traby: {
          price: null,
        },
        photos_traby: [],
      },
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      pagination: {
        current: 1,
        total: 0,
      },
      totalTrabies: 0,
      slider: [15, 100],
      categoryItems: [],
      careeresItems: [],
      categorySelect: null,
      careeresSelect: null,
      modalDatePicker: false,
      date: [],
      message: null,
      counterOffer: null,
      menu: null,
      trabiesList: [],
      mapFullyLoaded: false,
      bounds: {
        minLat: null,
        maxLat: null,
        minLng: null,
        maxLng: null,
      },
    };
  },
  created() {
    this.getTrabies();
  },
  methods: {
    async getTrabies() {
      try {
        const trabies = await axios.get(this.urlTrabies);
        const categories = await axios.get("api/category");
        const careeres = await axios.get("api/career");

        this.latitude = this.latitudeUser;
        this.longitude = this.longitudeUser;
        this.categoryItems = categories.data;
        this.careeresItems = careeres.data;
        this.trabiesList = trabies.data.data;
        this.pagination.current = trabies.data.current_page;
        this.pagination.total = trabies.data.last_page;
        this.totalTrabies = trabies.data.total;
      } catch (error) {
        console.error(error);
      }
    },
    since(time) {
      return moment(time).fromNow();
    },
    goProfile(profileId) {
      this.$router
        .push({ name: "Profile", params: { id: profileId } })
        .catch((err) => {});
    },
    boundsChanged() {
      let mapBounds = this.$refs.mapRef.$mapObject.getBounds();

      this.bounds = {
        minLat: mapBounds.getSouthWest().lat(),
        maxLat: mapBounds.getNorthEast().lat(),
        minLng: mapBounds.getSouthWest().lng(),
        maxLng: mapBounds.getNorthEast().lng(),
      };

      this.getTrabies();
    },
    onPageChange(page) {
      this.pagination.current = page;
      this.getTrabies();
    },
    contactMessageShow() {
      this.dialogTraby = false;
      this.dialogTrabyMessage = true;
    },
    showInfoTraby(id) {
      axios.get(`api/traby/${id}`).then((response) => {
        this.traby = response.data;
        this.dialogTraby = true;
      });
    },
    contactTraby(id) {
      axios.get(`api/traby/${id}`).then((response) => {
        this.traby = response.data;
        this.counterOffer = this.traby.price_init;
        this.dialogTrabyMessage = true;
      });
    },
    activeFilter() {
      this.pagination.current = 1;
      this.getTrabies();
      this.dialogFilter = false;
    },
    cleanFilter() {
      this.giveType = null;
      this.categorySelect = null;
      this.slider = [15, 100];
      this.date = [];
      this.activeFilter();
      this.dialogFilter = false;
    },
    sendMessage() {
      axios
        .post(`api/message`, {
          traby_id: this.traby.id,
          give: this.traby.user.id,
          description: this.message,
          price: this.counterOffer,
        })
        .then((response) => {
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Hemos enviado tu mensaje.",
            "success"
          );
          this.dialogTrabyMessage = false;
          this.traby = {
            description: null,
            time_need: null,
            latitude_init: null,
            longitude_init: null,
            category: {
              description: null,
            },
            photos_traby: [],
          };
        });
    },
    dayTraby(date) {
      let fecha = new Date(date);
      let options = {
        weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };

      return fecha.toLocaleDateString("es-ES", options);
    },
  },
  computed: {
    ...mapState({
      idUserLogin: (state) => state.auth.id,
      latitudeUser: (state) => state.auth.latitude,
      longitudeUser: (state) => state.auth.longitude,
    }),
    dateRangeText() {
      return this.date.join(" / ");
    },
    urlTrabies() {
      let trabiesableType = this.giveType ? this.giveType : null;
      let trabiesableId = null;
      if (this.giveType === 1) {
        trabiesableId = this.categorySelect ? this.categorySelect.id : null;
      } else {
        trabiesableId = this.careeresSelect ? this.careeresSelect.id : null;
      }

      let price =
        this.slider[0] == 15 && this.slider[1] == 100
          ? null
          : this.slider.join(".");

      let date = this.date.length == 0 ? "null" : this.date.join(".");

      let minLat = this.visibleMap ? this.bounds.minLat : null;
      let maxLat = this.visibleMap ? this.bounds.maxLat : null;
      let minLng = this.visibleMap ? this.bounds.minLng : null;
      let maxLng = this.visibleMap ? this.bounds.maxLng : null;

      return `api/traby/${this.pagination.current}/filter/${trabiesableType}/${trabiesableId}/${price}/${date}/${minLat}/${maxLat}/${minLng}/${maxLng}`;
    },
  },
};
</script>