<template>
  <v-row justify="center" no-gutters class="mt-3">
    <v-col cols="12" sm="9">
      <v-card outlined elevation="1" class="pa-3">
        <v-row no-gutters v-if="!level">
          <v-col cols="12">
            <v-alert color="teal" class="white--text font-weight-bold">
              <v-icon color="white" left>fa fa-info-circle</v-icon>Para completar tu perfil requerimos tu nivel de estudios.
            </v-alert>
          </v-col>
        </v-row>
        <v-tabs v-model="tab" color="teal" grow height="35">
          <v-tab>Estudios Realizados</v-tab>
          <v-tab>Cursos Realizados</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mt-2">
          <v-tab-item>
            <v-row dense>
              <v-col cols="auto">
                <v-radio-group v-model="level" row class="mt-0">
                  <v-radio label="Primaria" on-icon="fas fa-check-circle" :value="1" />
                  <v-radio label="Secundaria" on-icon="fas fa-check-circle" :value="2" />
                  <v-radio label="Tercer Nivel" on-icon="fas fa-check-circle" :value="3" />
                  <v-radio label="Cuarto Nivel" on-icon="fas fa-check-circle" :value="4" />
                  <v-radio label="Quinto Nivel" on-icon="fas fa-check-circle" :value="5" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" v-if="level >= 3">
                <v-combobox
                  :items="careers"
                  v-model="careersSelect"
                  label="Profesión"
                  background-color="inputBg"
                  item-text="description"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="6" v-if="level >= 3">
                <v-text-field
                  v-model="university"
                  label="Universidad"
                  background-color="inputBg"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row dense align="baseline" v-if="level >= 4">
              <v-col cols="9">
                <v-text-field
                  v-model="master"
                  label="Masterado"
                  background-color="inputBg"
                  hide-details
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-btn color="primary" outlined small @click="addAditionalStudies(1)">Agregar</v-btn>
              </v-col>
            </v-row>
            <v-row dense align="baseline" v-if="level >= 5">
              <v-col cols="9">
                <v-text-field
                  v-model="phd"
                  label="Doctorado"
                  background-color="inputBg"
                  hide-details
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-btn color="primary" outlined small @click="addAditionalStudies(2)">Agregar</v-btn>
              </v-col>
            </v-row>
            <v-row dense v-if="level > 3">
              <v-col cols="12">
                <v-simple-table class="tableShadow" :light="$vuetify.theme.dark">
                  <thead>
                    <tr class="white">
                      <th class="text-center">Descripcion de estudio</th>
                      <th width="30%" class="text-center">Tipo de Estudio</th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in aditionalStudies" :key="item.id">
                      <td class="text-center">{{ item.description }}</td>
                      <td
                        class="text-center"
                      >{{ item.further_studies_type ? item.further_studies_type.description : ''}}</td>
                      <td class="text-center">
                        <v-btn color="error" small icon @click="deleteAditionalStudies(item.id)">
                          <v-icon>far fa-trash-alt</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col cols="auto">
                <v-btn small outlined color="primary" @click="saveChanges">Guardar Estudios</v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row dense justify="space-between">
              <v-col cols="auto">
                <p class="mb-0 mt-5" v-text="'Cursos Realizados'" />
              </v-col>
              <v-col cols="auto mt-5">
                <v-btn color="primary" outlined small @click="dialogAddCourse= true">Agregar</v-btn>
              </v-col>
            </v-row>
            <v-divider />
            <v-row dense>
              <v-col cols="12">
                <v-simple-table class="tableShadow" :light="$vuetify.theme.dark">
                  <thead>
                    <tr class="white">
                      <th class="text-center">Descripcion del Curso</th>
                      <th width="30%" class="text-center">Duracion en horas</th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in courses" :key="item.id">
                      <td class="text-center">{{ item.description }}</td>
                      <td class="text-center">{{ item.hours }}</td>
                      <td class="text-center">
                        <v-btn color="error" small icon @click="deleteCourse(item.id)">
                          <v-icon>far fa-trash-alt</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialogAddCourse"
      scrollable
      overlay
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="teal d-flex justify-center primary white--text">Agregar Curso</v-card-title>
        <v-card-text class="py-3">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="course.description"
                label="Descripcion del Curso"
                background-color="inputBg"
                hide-details
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="course.hours"
                label="Duracion en Horas"
                background-color="inputBg"
                hide-details
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2">
          <v-row dense justify="center">
            <v-col cols="auto">
              <v-btn outlined small color="primary" @click="addCourse">Agregar Curso</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      tab: null,
      dialogAddCourse: false,
      dialogAddStudiesAditional: false,
      level: null,
      careers: [],
      careersSelect: null,
      courses: [],
      aditionalStudies: [],
      master: null,
      phd: null,
      university: null,
      course: {
        description: null,
        hours: null,
      },
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    async getAll() {
      try {
        const study = await axios.get(`api/studies`);
        const careers = await axios.get(`api/career`);
        const course = await axios.get(`api/course`);

        this.level = study.data.level;
        this.careers = careers.data;
        this.courses = course.data;

        if (study.data.level >= 3) {
          this.careersSelect = study.data.career;
          this.university = study.data.university;
        }

        if (study.data.level >= 4) {
          const furtherStudy = await axios.get("api/furtherStudies", {
            data: {},
          });
          this.aditionalStudies = furtherStudy.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    saveChanges() {
      axios
        .post("api/studies", {
          level: this.level,
          career_id: this.careersSelect ? this.careersSelect.id : null,
          university: this.university || null,
        })
        .then((response) => {
          this.$swal(
            "Correcto",
            "Hemos guardado satisfactoriamente tus estudios",
            "success"
          );
          this.$emit("getData");
        });
    },
    deleteAditionalStudies(id) {
      axios.delete(`api/furtherStudies/${id}`).then((response) => {
        this.getAll();
        this.$swal(
          "Operación Exitosa",
          "Estudio adicional eliminada con exito.",
          "success"
        );
      });
    },
    addAditionalStudies(studieType) {
      axios
        .post("api/furtherStudies", {
          description: studieType == 1 ? this.master : this.phd,
          further_studies_type: studieType,
        })
        .then((response) => {
          this.master = null;
          this.phd = null;
          const furtherStudy = axios
            .get("api/furtherStudies", {
              data: {},
            })
            .then((response) => {
              this.aditionalStudies = response.data;
            });

          this.$swal(
            "Correcto",
            "Hemos agregado tu estudio adiciona satisfactoriamente",
            "success"
          );
        });
    },
    addCourse() {
      axios
        .post("api/course", {
          description: this.course.description,
          hours: this.course.hours,
        })
        .then((response) => {
          this.getAll();
          this.dialogAddCourse = false;
          this.$swal(
            "Correcto",
            "Hemos agregado tu curso satisfactoriamente",
            "success"
          );
        });
    },
    deleteCourse(id) {
      axios.delete(`api/courses/${id}`).then((response) => {
        this.getAll();
        this.$swal(
          "Operación Exitosa",
          "Curso eliminado con exito.",
          "success"
        );
      });
    },
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.id,
    }),
  },
};
</script>