<template>
  <v-container grid-list-xs fluid fill-height class="back">
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-card max-width="350px" min-width="300px" class="ma-0">
          <v-row no-gutters class="pt-4">
            <v-col cols="12">
              <v-img src="images/logo.svg" aspect-ratio="3" contain height="80px" />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <p class="ma-0 text-caption">Version Beta</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4 mt-3">
            <v-col cols="12">
              <v-text-field
                outlined
                label="Razon Social"
                color="primary"
                v-model="name"
                dense
                hide-details
                background-color="inputBg"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4">
            <v-col cols="12">
              <v-text-field
                outlined
                label="Ruc"
                color="primary"
                v-model="identification"
                dense
                hide-details
                background-color="inputBg"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4">
            <v-col cols="12">
              <v-text-field
                outlined
                label="Email"
                color="primary"
                v-model="email"
                dense
                hide-details
                background-color="inputBg"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4">
            <v-col cols="12">
              <v-text-field
                outlined
                label="Contraseña"
                color="primary"
                v-model="password"
                type="password"
                dense
                hide-details
                background-color="inputBg"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-card-actions class="px-4 py-3 grey lighten-4">
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn
                  small
                  elevation="1"
                  dark
                  color="teal"
                  block
                  :loading="loadingButtons"
                  @click="register"
                >Registrar mi empresa</v-btn>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-btn
                  small
                  dark
                  outlined
                  color="primary"
                  block
                  :loading="loadingButtons"
                  @click="login"
                >Inicia sesión</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      identification: null,
      email: null,
      password: null,
      loadingButtons: false,
    };
  },
  methods: {
    register() {
      this.loadingButtons = true;
      axios
        .post("api/registerCompany", {
          name: this.name,
          identification: this.identification,
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.alertSuccess = true;
          this.alertError = false;
          this.errors = [];
          this.name = "";
          this.email = "";
          this.identification = "";
          this.password = "";
          this.$refs.form.reset();
          this.valid = true;
          this.loadingButtons = false;
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              this.alertSuccess = false;
              this.alertError = true;
              break;
            default:
              break;
          }
          this.loadingButtons = false;
        });
    },
    login() {
      this.$router.push({ name: "Login" }).catch((err) => {});
    },
  },
};
</script>

<style scoped>
.back {
  background: linear-gradient(to bottom, #00ccfa, #00a4cb, #007d99);
}
</style>