<template>
  <div>
    <v-row class="border mt-4" no-gutters>
      <v-col cols="12">
        <p class="mb-0" v-text="'Busqueda de Referencia'" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="filter"
          @keyup.enter="searchUser"
          label="Nombre, Apellido o Identificacion"
          color="primary"
          background-color="inputBg"
          append-outer-icon="fas fa-search"
          dense
          outlined
        />
      </v-col>
    </v-row>
    <v-row v-if="searchResult.length < 1 && result" no-gutters class="mt-n4">
      <v-col>
        <v-alert outlined type="error">Sin resultados para su busqueda</v-alert>
      </v-col>
    </v-row>
    <div v-if="result && searchResult.length >= 1">
      <v-row no-gutters class="mt-n6">
        <v-col>
          <v-simple-table :light="$vuetify.theme.dark">
            <thead>
              <tr>
                <th class="text-center">Nombre</th>
                <th class="text-center">Identificacion</th>
                <th class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in searchResult" :key="item.id">
                <td class="text-center">{{ item.name }} {{ item.last_name}}</td>
                <td class="text-center">{{ item.identification.slice(1, 6) }}...</td>
                <td class="text-center">
                  <v-btn outlined color="primary" small @click="addReference(item.id)">Seleccionar</v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="auto">
        <v-btn small color="primary" outlined @click="returnList()">Regresar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchResult: [],
      result: false,
      filter: null
    };
  },
  methods: {
    searchUser() {
      let filter = this.filter == null ? "" : this.filter.replace(/ /g, "");
      filter = filter == "" ? null : filter;
      axios
        .get(`api/users/search/${filter}`, {
          data: {}
        })
        .then(response => {
          this.result = true;
          this.searchResult = [];
          if (response.data.length > 0) this.searchResult = response.data;
        })
        .catch(error => {
          this.result = true;
          this.searchResult = [];
        });
    },
    addReference(userId) {
      axios
        .post(`api/reference`, {
          user_reference: userId,
          type: 1
        })
        .then(response => {
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Hemos enviado tu solicitud de referencia",
            "success"
          );
          this.returnList();
          this.$emit("getAll");
        });
    },
    returnList() {
      this.searchResult = [];
      this.result = false;
      this.filter = null;
      this.$emit("returnList");
    }
  }
};
</script>