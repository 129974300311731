<template>
  <v-container grid-list-xs fluid>
    <v-row justify="center">
      <v-col cols="12" sm="9">
        <v-row class="mb-n4">
          <v-col cols="12" md="4">
            <input type="file" class="d-none" ref="image" accept="image/*" @change="onFilePicked" />
            <v-hover v-slot:default="{ hover }">
              <v-img
                height="150px"
                width="150px"
                :src="user.image == null ? 'images/incognita.jpg' : `storage/avatar/${id}/${user.image}`"
                @click="changePic"
                class="mx-auto roundImage"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out v-card--reveal white--text justify-center align-center hoverUploadPic"
                  >
                    <v-icon color="white" x-large>fas fa-camera</v-icon>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
          </v-col>
          <v-col cols="12" md="4">
            <v-row no-gutters>
              <v-col cols="12">
                <p
                  class="text-capitalize mb-n1 text-center text-h6 text-uppercase"
                  v-text="`${user.last_name} ${user.name}`"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="mb-n1 text-center text-subtitle-2" v-text="user.email" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p
                  class="mb-n1 text-center text-subtitle-2"
                  v-text="`Fecha de Ingreso: ${since(user.created_at)}`"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p
                  class="mb-2 text-center text-subtitle-2"
                  v-text="`${user.address ? '' : 'Ecuador'}`"
                />
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-2">
              <v-col cols="12" class="d-flex justify-center">
                <p class="mb-1 lineasLados text-subtitle-2">Cuentas Vinculadas</p>
              </v-col>
            </v-row>

            <v-row no-gutters justify="space-around">
              <v-col cols="auto">
                <v-btn icon @click="checkSocial(1)">
                  <svg width="25" height="25">
                    <g>
                      <path
                        d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z"
                        fill="#34A853"
                      />
                      <path
                        d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z"
                        fill="#db4a39"
                      />
                    </g>
                  </svg>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn icon @click="checkSocial(2)">
                  <v-icon size="18" color="facebook" v-text="'fab fa-facebook-f'" />
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn icon @click="checkSocial(3)">
                  <v-icon size="18" color="twitter" v-text="'fab fa-twitter'" />
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn icon @click="dialogChangePassword = true">
                  <v-icon size="18" color="google" v-text="'fas fa-key'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" class="text-center">
            <v-row no-gutters>
              <v-col cols="12">
                <v-progress-circular
                  v-if="percent != 100"
                  :rotate="180"
                  :size="150"
                  :width="15"
                  :value="percent"
                  :color="percent <= 30 ? 'red' : percent <= 70 ? 'warning' : 'teal'"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p class="text-center mb-n1 text-body-1">Perfil - {{percent}} %</p>
                    </v-col>
                  </v-row>
                </v-progress-circular>

                <qrcode-vue
                  v-if="percent == 100"
                  :value="qrInformation"
                  size="150"
                  level="L"
                  foreground="#5D5D5D"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? '12' :'9'">
        <v-row justify="space-around">
          <v-col cols="12" md="3">
            <v-btn
              :class="typeView == 1 ? 'white--text' : ''"
              :color="typeView == 1 ? 'teal' : 'grayAlt'"
              :ripple="typeView == 1 ? false : true"
              :depressed="typeView == 1"
              :elevation="typeView != 1 ? '1' : '0'"
              block
              :small="$vuetify.breakpoint.name === 'md'"
              @click="typeView = 1"
              v-if="user.type !== 1"
            >Datos Personales</v-btn>
          </v-col>
          <v-col cols="12" md="3" v-if="user.type !== 1 && user.type !== 4">
            <v-btn
              :class="typeView == 2 ? 'white--text' : ''"
              :color="typeView == 2 ? 'teal' : 'grayAlt'"
              :ripple="typeView == 2 ? false : true"
              :depressed="typeView == 2"
              :elevation="typeView != 2 ? '1' : '0'"
              block
              :small="$vuetify.breakpoint.name === 'md'"
              @click="typeView = 2"
              v-if="user.type !== 1"
            >Formación</v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              :class="typeView == 3 ? 'white--text' : ''"
              :color="typeView == 3 ? 'teal' : 'grayAlt'"
              :ripple="typeView == 3 ? false : true"
              :depressed="typeView == 3"
              :elevation="typeView != 3 ? '1' : '0'"
              block
              :small="$vuetify.breakpoint.name === 'md'"
              @click="typeView = 3"
              v-if="user.type !== 1"
            >Habilidades</v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              :class="typeView == 4 ? 'white--text' : ''"
              :color="typeView == 4 ? 'teal' : 'grayAlt'"
              :ripple="typeView == 4 ? false : true"
              :depressed="typeView == 4"
              :elevation="typeView != 4 ? '1' : '0'"
              block
              :small="$vuetify.breakpoint.name === 'md'"
              @click="typeView = 4"
              v-if="user.type !== 1"
            >Referencias</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <PersonalData ref="personalData" v-show="typeView == 1" @fillData="fillData($event)" />
    <Studies v-if="typeView == 2" @getData="getData()" />
    <Skills v-if="typeView == 3" @getData="getData()" />
    <Reference v-if="typeView == 4" @getData="getData()" />

    <v-dialog
      v-model="dialogChangePassword"
      overlay
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text text-h6 ma-0">Cambio de Contraseña</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-container grid-list-xs>
          <v-row>
            <v-col cols="12" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
              <v-text-field
                type="password"
                v-model="password"
                label="Contraseña Nueva"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
              <v-text-field
                type="password"
                v-model="passwordConfirm"
                label="Confirme Contraseña Nueva"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pt-0 pb-2">
          <v-row no-gutters justify="end">
            <v-col cols="auto" class="mr-2">
              <v-btn outlined small color="primary" @click="dialogChangePassword = false">Regresar</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn outlined small color="primary" @click="changePassword">Guardar Cambios</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRemplaceOrDelete"
      overlay
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text text-h6 ma-0">Remplazar o Eliminar</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-container grid-list-xs>
          <v-row no-gutters>
            <v-col>
              <p
                class="ma-0"
              >Actualmente tienes una cuenta de este tipo agregada, escoja una opcion de accion. (Recuerde que de escojer desvincular tiene que tener un metodo alternativo para ingresar.)</p>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-row no-gutters justify="end">
            <v-col cols="auto" class="mr-2">
              <v-btn color="primary" small outlined @click="unlinkSocial">Desvincular</v-btn>
            </v-col>
            <v-col cols="auto" class="mr-2">
              <v-btn color="primary" small outlined @click="socialGetUser">Cambiar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import PersonalData from "./PersonalData";
import Skills from "./Skills";
import Studies from "./Studies";
import Reference from "./Reference/Reference";
import QrcodeVue from "qrcode.vue";
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      percent: 0,
      dialogRemplaceOrDelete: false,
      dialogChangePassword: false,
      password: null,
      passwordConfirm: null,
      typeView: 1,
      typeSocialSelect: null,
      user: {
        name: "",
        image: null,
      },
    };
  },
  components: {
    PersonalData,
    Reference,
    Skills,
    Studies,
    QrcodeVue,
  },
  mounted() {
    window.addEventListener("message", this.onMessage, false);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    since(time) {
      return moment(time).fromNow();
    },
    checkSocial(type) {
      this.typeSocialSelect = type;
      axios
        .post("api/checkSocial", {
          type: type,
        })
        .then((response) => {
          this.dialogRemplaceOrDelete = response.data == 1 ? true : false;

          if (response.data == 0) this.socialGetUser();
        });
    },
    unlinkSocial() {
      axios
        .post("api/unlinkSocial", {
          type: this.typeSocialSelect,
        })
        .then((response) => {
          this.typeSocialSelect = null;
          this.dialogRemplaceOrDelete = false;
          this.$swal(
            "Correcto",
            "Hemos realizado la desvinculacion satisfactoriamente",
            "success"
          );
        });
    },
    socialGetUser() {
      this.dialogRemplaceOrDelete = false;
      axios
        .post("api/socialLogin", { red: this.typeSocialSelect })
        .then((response) => {
          let width = 400;
          let height = this.$vuetify.breakpoint.height - 150;

          var dualScreenLeft =
            window.screenLeft != undefined ? window.screenLeft : window.screenX;

          var dualScreenTop =
            window.screenTop != undefined ? window.screenTop : window.screenY;

          var systemZoom =
            this.$vuetify.breakpoint.width / window.screen.availWidth;

          var left =
            (this.$vuetify.breakpoint.width - width) / 2 / systemZoom +
            dualScreenLeft;

          var top =
            (this.$vuetify.breakpoint.height - height) / 2 / systemZoom +
            dualScreenTop;

          var newWindow = window.open(
            response.data,
            "Autenticacion Traby App",
            "scrollbars=yes, width=" +
              width / systemZoom +
              ", height=" +
              height / systemZoom +
              ", top=" +
              top +
              ", left=" +
              left
          );

          if (window.focus) newWindow.focus();
        });
    },
    onMessage(e) {
      if (e.origin !== window.origin || !e.data.name) return;
      axios.post("api/addOrChangeSocial", e.data).then((response) => {
        this.$swal(
          "Correcto",
          "Hemos vinculado tu red satisfactoriamente",
          "success"
        );
      });
    },
    changePassword() {
      if (this.password !== this.passwordConfirm) return;
      axios
        .put("api/changePassword", {
          password: this.password,
        })
        .then((response) => {
          this.password = null;
          this.passwordConfirm = null;
          this.dialogChangePassword = false;
          this.$swal(
            "Correcto",
            "Contraseña asignada a tu cuenta satisfactoriamente",
            "success"
          );
        })
        .catch((error) => {
          this.password = null;
          this.passwordConfirm = null;
          this.dialogChangePassword = false;
          this.$swal(
            "Correcto",
            "Hubo un error inesperado por favor intentelo mas tarde",
            "error"
          );
        });
    },
    fillData(data) {
      this.user = data;
      this.percent = data.percent;
    },
    getData() {
      this.$refs.personalData.getAll();
    },
    changePic() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        let image = files[0].name;
        if (image.lastIndexOf(".") <= 0) return;
        let fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.onloadend = () => {
          axios
            .put(`api/user/change-image`, {
              photo_perfil: fr.result,
            })
            .then((response) => {
              this.user.image = response.data;
              this.$store.dispatch("setPhotoPerfil", {
                photoPerfil: response.data,
              });
            });
          this.getData();
        };
      } else {
        this.user.image = "";
      }
    },
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.id,
    }),
    qrInformation() {
      return `Nombre: ${this.user.name} Identificacion: ${this.user.identification} Correo: ${this.user.email} Telefono: ${this.user.phone} / ${this.user.mobile}
      `;
    },
  },
};
</script>