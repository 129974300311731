<template>
  <v-container grid-list-xs fluid grid>
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-row class="border borderTop" align="center">
          <v-col cols="auto">
            <v-btn color="primary" small @click="dialogFilter = true" outlined>
              <v-icon color="teal" size="16" left>fas fa-search</v-icon>
              <div>Buscar Traber</div>
            </v-btn>
          </v-col>

          <v-spacer />
          <v-col cols="auto" v-if="$vuetify.breakpoint.width > 765">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Mostrar Mapa"
              v-model="visibleMap"
              color="primary"
              on-icon="far fa-check-square"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-responsive
            class="overflow-y-auto"
            :height="`${this.$vuetify.breakpoint.height-230}px`"
            width="50%"
          >
            <v-col cols="12" sm="12" class="py-0 px1">
              <v-row>
                <v-col cols="12" class="border" v-if="traberList.length != 0">
                  <p class="mb-0">
                    Tenemos
                    <span>{{totalTrabers}}</span> trabers ideales para ti
                  </p>
                </v-col>
                <v-col cols="12" v-if="traberList.length == 0">
                  <v-row no-gutters>
                    <v-col>
                      <p class="mb-0">
                        <span class>No hay resultados de Trabys</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <p
                        class="text-justify"
                      >No se han encontrado trabys para ti, mejora tu búsqueda cambiando las fechas, eliminando los filtros o ampliando la zona en el mapa.</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" :sm="visibleMap ? 6 : 3" v-for="item in traberList" :key="item.id">
                  <v-card outlined elevation="1">
                    <v-list-item @click="route(item.id)" class="px-2">
                      <v-list-item-avatar class="mr-2 my-1">
                        <v-img
                          :src="item.image ? `storage/avatar/${item.id}/${item.image}` : 'images/incognita.jpg'"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="`${item.name} ${item.last_name}`"
                          class="text-subtitle-2 font-weight-regular"
                        />
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider />
                    <v-card-text class="px-3 py-2">
                      <v-row no-gutters>
                        <v-col>
                          <p
                            class="mb-2 text-justify text-truncate"
                          >{{item.description ? item.description : 'Sin Descripción.'}}</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <p class="mb-0 d-flex">
                            <v-icon color="teal" left size="14">fas fa-map-marker-alt</v-icon>
                            {{item.parroquia ? item.parroquia.canton.canton : 'CUENCA'}}, {{item.parroquia ? item.parroquia.canton.provincia.provincia : 'AZUAY'}}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider class="mb-2" />

                    <v-card-actions class="px-3 pt-0 pb-2">
                      <v-row justify="space-between" no-gutters>
                        <v-col>
                          <v-btn color="primary" text small class="pl-0">
                            <v-icon color="teal" size="20" class="pr-2">far fa-comment-alt</v-icon>Contactar
                          </v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col class="d-flex justify-end">
                          <v-btn color="primary" icon small @click="route(item.id)">
                            <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                          </v-btn>

                          <v-btn color="primary" icon small>
                            <v-icon color="teal" size="20">fa fa-share-alt</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-responsive>
          <v-col cols="12" sm="6" v-if="visibleMap && $vuetify.breakpoint.width > 765" class="pa-0">
            <GmapMap
              ref="mapRef"
              @idle="boundsChanged"
              :center="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
              :clickable="true"
              :zoom="16"
              :options="mapOptions"
              :style="`height: ${this.$vuetify.breakpoint.height-230}px`"
            >
              <div v-for="item in traberList" :key="item.id">
                <GmapMarker
                  icon="images/maker.png"
                  :title="`${item.last_name}, ${item.name}`"
                  :position="{ lat: Number(item.latitude), lng: Number(item.longitude) }"
                />
              </div>
            </GmapMap>
          </v-col>
        </v-row>

        <v-row justify="center" class="border borderTop" no-gutters align="center">
          <v-col cols="12" class="py-2 d-flex justify-center">
            <v-pagination
              :length="pagination.total"
              v-model="pagination.current"
              next-icon="fas fa-caret-right"
              prev-icon="fas fa-caret-left"
              @input="onPageChange"
              circle
              total-visible="7"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogFilter" persistent max-width="400px" transition="dialog-transition">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text title ma-0">Buscar traber</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="py-2">
          <v-row no-gutters>
            <v-col>
              <p class="mb-0">Tipo de traby:</p>
            </v-col>
          </v-row>
          <v-divider />
          <v-row no-gutters class="mt-n3">
            <v-col>
              <v-radio-group row v-model="giveType" hide-details>
                <v-radio label="Habilidades" on-icon="fas fa-check-circle" value="1" />
                <v-radio label="Titulo Profesional" on-icon="fas fa-check-circle" value="2" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-if="giveType === '1'"
                :items="categoryItems"
                v-model="categorySelect"
                hide-details
                item-text="description"
                item-value="id"
                label="Categoria"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />

              <v-combobox
                v-if="giveType === '2'"
                :items="careeresItems"
                v-model="careeresSelect"
                hide-details
                item-text="description"
                item-value="id"
                label="Profesion"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row no-gutters>
            <v-row no-gutters justify="space-around">
              <v-col cols="auto">
                <v-btn color="primary" small outlined @click="cleanFilter">Cancelar</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" small outlined @click="activeFilter">Buscar</v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialogFilter: false,
      visibleMap: true,
      giveType: "1",
      latitude: 0,
      longitude: 0,
      mapOptionsResume: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        disableDefaultUI: true,
        draggable: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }]
          }
        ]
      },
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }]
          }
        ]
      },
      pagination: {
        current: 1,
        total: 0
      },
      totalTrabers: 0,
      slider: [15, 100],
      categoryItems: [],
      categorySelect: null,
      careeresItems: [],
      careeresSelect: null,
      modalDatePicker: false,
      date: [],
      menu: null,
      traberList: [],
      search: "",
      mapFullyLoaded: false,
      bounds: {
        minLat: null,
        maxLat: null,
        minLng: null,
        maxLng: null
      }
    };
  },
  created() {
    this.getTrabers();
  },
  methods: {
    async getTrabers() {
      try {
        const traberes = await axios.get(this.urlTrabies);
        const categories = await axios.get("api/category");
        const careeres = await axios.get("api/career");

        this.latitude = this.latitudeUser;
        this.longitude = this.longitudeUser;
        this.categoryItems = categories.data;
        this.careeresItems = careeres.data;
        this.traberList = traberes.data.data;
        this.pagination.current = traberes.data.current_page;
        this.pagination.total = traberes.data.last_page;
        this.totalTrabers = traberes.data.total;
      } catch (error) {
        console.error(error);
      }
    },
    boundsChanged() {
      let mapBounds = this.$refs.mapRef.$mapObject.getBounds();

      this.bounds = {
        minLat: mapBounds.getSouthWest().lat(),
        maxLat: mapBounds.getNorthEast().lat(),
        minLng: mapBounds.getSouthWest().lng(),
        maxLng: mapBounds.getNorthEast().lng()
      };

      this.getTrabers();
    },
    onPageChange(page) {
      this.pagination.current = page;
      this.getTrabers();
    },
    route(id) {
      if (id != 0) {
        this.$router
          .push({ name: "Profile", params: { id: id } })
          .catch(err => {});
      }
    },
    activeFilter() {
      this.pagination.current = 1;
      this.getTrabers();
      this.dialogFilter = false;
    },
    cleanFilter() {
      this.search = "";
      this.categorySelect = null;
      this.slider = [15, 100];
      this.date = [];
      this.activeFilter();
      this.dialogFilter = false;
    }
  },
  computed: {
    ...mapState({
      latitudeUser: state => state.auth.latitude,
      longitudeUser: state => state.auth.longitude
    }),
    urlTrabies() {
      let trabiesableType = this.giveType ? this.giveType : null;
      let trabiesableId = null;

      if (this.giveType === "1") {
        trabiesableId = this.categorySelect ? this.categorySelect.id : null;
      } else {
        trabiesableId = this.careeresSelect ? this.careeresSelect.id : null;
      }

      let category =
        this.categorySelect == null ? "null" : this.categorySelect.id;
      let minLat = this.visibleMap == false ? "null" : this.bounds.minLat;
      let maxLat = this.visibleMap == false ? "null" : this.bounds.maxLat;
      let minLng = this.visibleMap == false ? "null" : this.bounds.minLng;
      let maxLng = this.visibleMap == false ? "null" : this.bounds.maxLng;
      return `api/user/${this.pagination.current}/filter/${trabiesableType}/${trabiesableId}/${minLat}/${maxLat}/${minLng}/${maxLng}`;
    }
  }
};
</script>