<template>
  <v-container grid-list-xs fluid>
    <v-row justify="center">
      <v-col cols="12" sm="9">
        <v-tabs v-model="tabs" grow>
          <v-tab class="backgroundApp">Trabys Adjudicados</v-tab>
          <v-tab class="backgroundApp">Trabys Publicados</v-tab>
          <v-tab class="backgroundApp">Metricas</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item class="backgroundApp mt-5">
            <template v-if="trabysAssigned.length <= 0">
              <v-row>
                <v-col>
                  <p class="mb-0 text-h6 primary--text">No tienes trabys Adjudicados a la fecha</p>
                </v-col>
              </v-row>
            </template>
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-for="(item, index) in trabysAssigned"
                :key="index"
              >
                <v-card outlined elevation="1">
                  <v-list-item @click="goProfile(item.traby.give)" class="px-2">
                    <v-list-item-avatar class="mr-2 my-1">
                      <v-img
                        :src="item.traby.user.image ?`storage/avatar/${item.traby.user.id}/${item.traby.user.image}` : 'images/incognita.jpg'"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.traby.user.name} ${item.traby.user.last_name}`"
                        class="text-subtitle-2 font-weight-regular"
                      />
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider />

                  <v-card-text class="px-2 py-1">
                    <v-row no-gutters>
                      <v-col>
                        <p
                          class="text-truncate mb-0 text-capitalize"
                          v-text="trabysAssigned ? item.traby.title : ''"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <p class="text-caption text-truncate mb-0 text-capitalize">
                          <span
                            class="font-weight-bold"
                          >{{item.traby.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</span>
                          {{item.traby.trabiesable ? item.traby.trabiesable.description : ''}}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col class="pa-0">
                        <v-row no-gutters>
                          <v-col>
                            <p
                              class="text-caption mb-0 text-truncate text-capitalize"
                              v-text="`Requerido en: ${since(item.traby.time_need)}`"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="px-2 py-1">
                    <v-row justify="space-between" no-gutters>
                      <v-col v-if="item.status < 3">
                        <v-btn color="primary" text small @click="showKey(item.token)" class="pl-0">
                          <v-icon color="teal" size="20" class="pr-2">fa fa-key</v-icon>Llave
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="primary"
                          text
                          small
                          v-if="item.status === 3"
                          @click="Qualifications(item, 1)"
                          class="pl-0"
                        >
                          <v-icon color="teal" size="20" class="pr-2">far fa-check-circle</v-icon>Puntuar
                        </v-btn>
                        <v-btn
                          v-if="item.status === 4"
                          color="primary"
                          text
                          small
                          class="pl-0"
                          disabled
                        >
                          <v-icon color="teal" size="20" class="pr-2">far fa-check-circle</v-icon>Traby Finalizado
                        </v-btn>
                      </v-col>
                      <v-spacer />
                      <v-col class="d-flex justify-end">
                        <v-btn color="primary" icon small @click="showInfoTraby(item.traby.id)">
                          <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="backgroundApp mt-5">
            <template v-if="trabysPublished.length <= 0">
              <v-row>
                <v-col>
                  <p class="mb-0 text-h6 primary--text">No tienes trabys Publicados a la fecha</p>
                </v-col>
              </v-row>
            </template>
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-for="(item, index) in trabysPublished"
                :key="index"
              >
                <v-card outlined elevation="1">
                  <v-list-item @click="goProfile(item.give)" class="px-2">
                    <v-list-item-avatar class="mr-2 my-1">
                      <v-img
                        :src="item.user.image ? `storage/avatar/${item.user.id}/${item.user.image}` : 'images/incognita.jpg'"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.user.name} ${item.user.last_name}`"
                        class="text-subtitle-2 font-weight-regular"
                      />
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider />

                  <v-card-text class="px-2 py-1">
                    <v-row no-gutters>
                      <v-col>
                        <p
                          class="text-truncate mb-0 text-capitalize"
                          v-text="trabysAssigned ? item.title : ''"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <p class="text-caption text-truncate mb-0 text-capitalize">
                          <span
                            class="font-weight-bold"
                          >{{item.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</span>
                          {{item.trabiesable ? item.trabiesable.description : ''}}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col class="pa-0">
                        <v-row no-gutters>
                          <v-col>
                            <p
                              class="text-caption mb-0 text-truncate text-capitalize"
                              v-text="`Requerido en: ${since(item.time_need)}`"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="item.traby_controls">
                      <v-col class="pa-0">
                        <v-row no-gutters>
                          <v-col>
                            <p
                              style="cursor:pointer"
                              @click="goProfile(item.traby_controls.recive.id)"
                              class="text-caption mb-0 text-truncate text-capitalize teal--text"
                              v-text="`Asignado a: ${item.traby_controls.recive.name} ${item.traby_controls.recive.last_name}`"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-else>
                      <v-col class="pa-0">
                        <v-row no-gutters>
                          <v-col>
                            <p class="mb-0" v-text="`Sin asginar`" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="px-2 py-1">
                    <v-row justify="space-between" no-gutters>
                      <v-col>
                        <v-btn
                          v-if="item.traby_controls && item.traby_controls.status== 1"
                          color="primary"
                          text
                          small
                          @click="initTraby(item.traby_controls.token, item.traby_controls.id)"
                          class="pl-0"
                        >
                          <v-icon color="teal" size="20" class="pr-2">fa fa-key</v-icon>Iniciar Traby
                        </v-btn>
                        <v-btn
                          v-if="item.traby_controls && item.traby_controls.status== 2"
                          color="primary"
                          text
                          small
                          class="pl-0"
                          @click="Qualifications(item.traby_controls, 0)"
                        >
                          <v-icon color="teal" size="20" class="pr-2">far fa-check-circle</v-icon>Finalizar y Puntuar
                        </v-btn>
                        <v-btn
                          v-if="item.traby_controls && item.traby_controls.status== 3"
                          color="primary"
                          text
                          small
                          class="pl-0"
                          disabled
                        >
                          <v-icon color="teal" size="20" class="pr-2">far fa-check-circle</v-icon>Traby Finalizado
                        </v-btn>
                        <v-btn
                          v-if="!item.traby_controls"
                          color="primary"
                          text
                          small
                          disabled
                          class="pl-0"
                        >
                          <v-icon color="teal" size="20" class="pr-2">far fa-times-circle</v-icon>sin Asignar
                        </v-btn>
                      </v-col>
                      <v-spacer />
                      <v-col class="d-flex justify-end">
                        <v-btn color="primary" icon small @click="showInfoTraby(item.id)">
                          <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="backgroundApp mt-5">
            <v-row dense justify="center">
              <v-col cols="auto">
                <Metrics />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTraby" max-width="400px">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text title ma-0">Detalles del Traby</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <GmapMap
                ref="mapRef"
                :center="{ lat: Number(trabyInfo.latitude_init), lng: Number(trabyInfo.longitude_init) }"
                :clickable="true"
                :zoom="16"
                :options="mapOptions"
                class="heightMap150"
              >
                <GmapMarker
                  icon="images/maker.png"
                  :clickable="true"
                  :draggable="true"
                  :position="{ lat: Number(trabyInfo.latitude_init), lng: Number(trabyInfo.longitude_init) }"
                />
              </GmapMap>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-subtitle-2 ma-0">Descripcion:</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border">
            <v-col>
              <p class="text-justify text-body-2 mb-1">{{trabyInfo.description}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p
                class="mb-1 text-subtitle-2"
              >{{trabyInfo.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</p>
            </v-col>
            <v-col cols="6">
              <p
                class="text-body-2 mb-1 text-right"
              >{{trabyInfo.trabiesable ? trabyInfo.trabiesable.description : ''}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline" justify="space-between">
            <v-col cols="auto">
              <p class="mb-1 text-subtitle-2">Fecha y Hora:</p>
            </v-col>
            <v-col cols="auto">
              <p class="text-body-2 mb-1 text-right">{{dayTraby(trabyInfo.time_need)}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p class="mb-1 text-subtitle-2">Oferta:</p>
            </v-col>
            <v-col cols="6">
              <p
                class="text-body-2 mb-1 text-right"
              >$ {{trabyInfo.conversation_calendar.last_price.price}}</p>
            </v-col>
          </v-row>
          <div v-if="trabyInfo.photos_traby.length > 0">
            <v-row no-gutters class>
              <v-col cols="12">
                <p class="text-subtitle-2 my-2 text-center">Referencia Visual</p>
              </v-col>
            </v-row>
            <v-row no-gutters justify="space-between">
              <v-col
                cols="3"
                class="d-flex justify-center mx-1 mb-3"
                v-for="(image,i) in trabyInfo.photos_traby"
                :key="i"
              >
                <v-img
                  height="50px"
                  width="50px"
                  :src="`storage/trabies/${trabyInfo.id}/${image.image}`"
                />
              </v-col>
            </v-row>
          </div>
          <v-row no-gutters>
            <v-col>
              <v-btn outlined small block color="primary" @click="dialogTraby = false">Regresar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogQualifications" max-width="400px">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text text-h6 ma-0">Puntuación del Traby</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-4 pt-4 pb-0">
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <p class="text-h6">Calificación</p>
            </v-col>
          </v-row>
          <v-row no-gutters justify="space-around">
            <v-col cols="auto">
              <v-btn fab icon small @click="changeCalification(1)">
                <v-icon
                  class="mb-1"
                  color="warning"
                  size="32"
                >{{calification >= 1 ? 'fas fa-star' : 'far fa-star'}}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn fab icon small @click="changeCalification(2)">
                <v-icon
                  class="mb-1"
                  color="warning"
                  size="32"
                >{{calification >= 2 ? 'fas fa-star' : 'far fa-star'}}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn fab icon small @click="changeCalification(3)">
                <v-icon
                  class="mb-1"
                  color="warning"
                  size="32"
                >{{calification >= 3 ? 'fas fa-star' : 'far fa-star'}}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn fab icon small @click="changeCalification(4)">
                <v-icon
                  class="mb-1"
                  color="warning"
                  size="32"
                >{{calification >= 4 ? 'fas fa-star' : 'far fa-star'}}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn fab icon small @click="changeCalification(5)">
                <v-icon
                  class="mb-1"
                  color="warning"
                  size="32"
                >{{calification >= 5 ? 'fas fa-star' : 'far fa-star'}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-4">
            <v-col cols="12">
              <v-textarea
                v-model="referenceFinish"
                label="Recomendaciones"
                background-color="inputBg"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row dense justify="center" class="mt-2">
            <v-col cols="auto">
              <v-btn outlined small color="primary" @click="finishTraby">Puntuar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Metrics from "./Metrics";
moment.locale("es");

export default {
  data() {
    return {
      referenceFinish: null,
      tabs: 0,
      calification: 0,
      dialogQualifications: false,
      rating: null,
      trabysAssigned: [],
      trabysPublished: [],
      trabyInfo: {
        description: null,
        time_need: null,
        latitude_init: null,
        longitude_init: null,
        price_traby: {
          price: null,
        },
        conversation_calendar: {
          last_price: {
            price: 0,
          },
        },
        photos_traby: [],
      },
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      dialogTraby: false,
      idTrabyControlChange: 0,
      typeQualification: 0,
    };
  },
  components: {
    Metrics,
  },
  created() {
    this.trabyAssigned();
    this.trabyPublished();
  },
  methods: {
    since(time) {
      return moment(time).fromNow();
    },
    dayTraby(time) {
      return `${moment(time).format("lll")}`;
    },
    goProfile(profileId) {
      this.$router
        .push({ name: "Profile", params: { id: profileId } })
        .catch((err) => {});
    },
    showKey(token) {
      this.$swal("Llave Secreta", token.substring(11, 21), "info");
    },
    async initTraby(token, id) {
      const key = await this.$swal({
        title: "Ingrese Clave para iniciar",
        input: "password",
        inputPlaceholder: "Clave",
      });

      axios
        .put(`api/changeStatusTraby/${id}`, {
          key: key.value,
        })
        .then((response) => {
          this.$swal("Llave correcta", "Traby iniciado", "success");
          this.trabyPublished();
        })
        .error((error) => {
          this.$swal("Llave Inorrecta", "Traby no iniciado", "error");
        });
    },
    trabyAssigned() {
      axios.get(`api/trabyByUserInfo/${this.id}`).then((response) => {
        this.trabysAssigned = response.data;
      });
    },
    trabyPublished() {
      axios.get(`api/trabyByUserPublished`).then((response) => {
        this.trabysPublished = response.data;
      });
    },
    showInfoTraby(id) {
      axios.get(`api/traby/${id}`).then((response) => {
        this.trabyInfo = response.data;
        this.dialogTraby = true;
      });
    },
    changeCalification(calification) {
      this.calification = calification;
    },
    Qualifications(trabyControl, type) {
      this.idTrabyControlChange = trabyControl;
      this.dialogQualifications = true;
      this.typeQualification = type;
    },
    finishTraby() {
      axios
        .put(`api/finishTraby/${this.idTrabyControlChange.id}`, {
          qualification: this.calification,
          reference: this.referenceFinish,
          type: this.typeQualification,
        })
        .then((response) => {
          this.trabyPublished();
          this.trabyAssigned();
          this.dialogQualifications = false;
        });
    },
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.id,
    }),
  },
};
</script>
<style>
.chart div {
  font: 10px sans-serif;
  background-color: steelblue;
  text-align: right;
  padding: 3px;
  margin: 1px;
  color: white;
}
.container-chart {
  /* width: 50%; */
  margin: auto;
}
</style>