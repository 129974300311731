<template>
  <v-app>
    <router-view></router-view>
    <v-speed-dial
      v-if="
        $router.currentRoute.name !== 'Login' &&
        $router.currentRoute.name !== 'Message' &&
        $router.currentRoute.name !== 'registerCompany' &&
        $router.currentRoute.name !== 'Home'
      "
      v-model="fab"
      bottom
      right
      direction="top"
      open-on-hover
      transition="slide-y-reverse-transition"
      fixed
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="teal" small fab depressed>
          <v-icon color="white">{{fab ? 'fa fa-times' : 'fa fa-plus'}}</v-icon>
        </v-btn>
      </template>
      <v-btn fab depressed dark small color="teal" @click="route('Account')">
        <v-icon color="white" size="18">far fa-user</v-icon>
      </v-btn>
      <v-btn fab depressed dark small color="teal" @click="route('SearchTraber')">
        <v-icon color="white" size="18">fa fa-search</v-icon>
      </v-btn>
      <v-btn fab depressed dark small color="teal" @click="route('SearchTraby')">
        <v-icon color="white" size="18">fa fa-search-dollar</v-icon>
      </v-btn>
      <v-btn fab depressed dark small color="teal" @click="route('GiveTraby')">
        <v-icon color="white" size="18">far fa-newspaper</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      fab: false,
    };
  },
  methods: {
    route(ruta) {
      this.$router.push({ name: ruta }).catch((err) => {});
    },
  },
};
</script>