<template>
  <v-row justify="center">
    <v-col cols="11" sm="9">
      <v-row no-gutters justify="center" class="my-4">
        <v-col cols="6">
          <v-text-field
            v-model="searchLocal"
            label="Buscar en traby"
            background-color="inputBg"
            outlined
            dense
            hide-details
            @keyup.enter="fetchAll"
          >
            <template v-slot:prepend-inner>
              <template>
                <v-icon color="teal" class="mt-1" left size="16">fa fa-search</v-icon>
              </template>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <template v-if="typeUser !== 1">
        <v-row no-gutters>
          <v-col cols="12">
            <p
              :class="titleSize"
              class="mb-1 text-justify"
              v-text="`Trabys Encontrados en tu busqueda`"
            />
          </v-col>
        </v-row>
        <v-divider />

        <v-row v-if="trabies.length <= 0">
          <v-col cols="12">
            <p>No hemos encontrado Trabys relacionados a su busqueda</p>
          </v-col>
        </v-row>

        <v-row v-if="trabies.length > 0">
          <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in trabies" :key="index">
            <v-card outlined elevation="1">
              <v-list-item @click="goProfile(item.give)" class="px-2">
                <v-list-item-avatar class="mr-2 my-1">
                  <v-img
                    :src="item.user.image ? `storage/avatar/${item.user.id}/${item.user.image}` : 'images/incognita.jpg'"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.user.name} ${item.user.last_name}`"
                    class="text-subtitle-2 font-weight-regular"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-card-text class="px-2 py-1">
                <v-row no-gutters>
                  <v-col>
                    <p
                      class="text-truncate mb-0 text-capitalize"
                      v-text="trabies ? item.title : ''"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <p class="text-caption text-truncate mb-0 text-capitalize">
                      <span
                        class="font-weight-bold"
                      >{{item.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</span>
                      {{item.trabiesable ? item.trabiesable.description : ''}}
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col class="pa-0">
                    <v-row no-gutters>
                      <v-col>
                        <p
                          class="text-caption mb-0 text-truncate text-capitalize"
                          v-text="`Publicado: ${since(item.created_at)}`"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions class="px-2 py-1">
                <v-row justify="space-between" no-gutters>
                  <v-col>
                    <v-btn color="primary" text small @click="contactTraby(item.id)" class="pl-0">
                      <v-icon color="teal" size="20" class="pr-2">far fa-comment-alt</v-icon>Contactar
                    </v-btn>
                  </v-col>
                  <v-spacer />
                  <v-col class="d-flex justify-end">
                    <v-btn color="primary" icon small @click="showInfoTraby(item.id)">
                      <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-if="typeUser !== 2">
        <v-row no-gutters>
          <v-col cols="12">
            <p
              :class="titleSize"
              class="mb-1 text-justify"
              v-text="`Trabers Encontrados en tu busqueda`"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row v-if="trabers.length <= 0">
          <v-col cols="12">
            <p>No hemos encontrado Trabers relacionados a su busqueda</p>
          </v-col>
        </v-row>

        <v-row v-if="trabers.length > 0">
          <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in trabers" :key="index">
            <v-card outlined elevation="1">
              <v-list-item @click="goProfile(item.id)" class="px-2">
                <v-list-item-avatar class="mr-2 my-1">
                  <v-img
                    :src="item.image ? `storage/avatar/${item.id}/${item.image}` : 'images/incognita.jpg'"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.name} ${item.last_name}`"
                    class="text-subtitle-2 font-weight-regular"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-divider />
              <v-card-text class="px-3 py-2">
                <v-row no-gutters>
                  <v-col>
                    <p
                      class="mb-2 text-justify text-truncate"
                    >{{item.description ? item.description : 'Sin Descripción.'}}</p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <p class="mb-0 d-flex">
                      <v-icon color="teal" left size="14">fas fa-map-marker-alt</v-icon>
                      {{item.parroquia ? item.parroquia.canton.canton : 'CUENCA'}}, {{item.parroquia ? item.parroquia.canton.provincia.provincia : 'AZUAY'}}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mb-2" />

              <v-card-actions class="px-3 pt-0 pb-2">
                <v-row justify="space-between" no-gutters>
                  <v-col>
                    <v-btn color="primary" text small class="pl-0">
                      <v-icon color="teal" size="20" class="pr-2">far fa-comment-alt</v-icon>Contactar
                    </v-btn>
                  </v-col>
                  <v-spacer />
                  <v-col class="d-flex justify-end">
                    <v-btn color="primary" icon small @click="goProfile(item.id)">
                      <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                    </v-btn>

                    <v-btn color="primary" icon small>
                      <v-icon color="teal" size="20">fa fa-share-alt</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <v-dialog v-model="dialogTraby" max-width="400px">
        <v-card>
          <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="auto">
                <p class="teal--text title ma-0">Detalles del Traby</p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <GmapMap
                  ref="mapRef"
                  :center="{ lat: Number(trabyInfo.latitude_init), lng: Number(trabyInfo.longitude_init) }"
                  :clickable="true"
                  :zoom="16"
                  :options="mapOptions"
                  class="heightMap150"
                >
                  <GmapMarker
                    icon="images/maker.png"
                    :clickable="true"
                    :draggable="true"
                    :position="{ lat: Number(trabyInfo.latitude_init), lng: Number(trabyInfo.longitude_init) }"
                  />
                </GmapMap>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="text-subtitle-2 ma-0">Descripcion:</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="border">
              <v-col>
                <p class="text-justify text-body-2 mb-1">{{trabyInfo.description}}</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="border" align="baseline">
              <v-col cols="6">
                <p
                  class="mb-1 text-subtitle-2"
                >{{trabyInfo.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</p>
              </v-col>
              <v-col cols="6">
                <p
                  class="text-body-2 mb-1 text-right"
                >{{trabyInfo.trabiesable ? trabyInfo.trabiesable.description : ''}}</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="border" align="baseline" justify="space-between">
              <v-col cols="auto">
                <p class="mb-1 text-subtitle-2">Fecha y Hora:</p>
              </v-col>
              <v-col cols="auto">
                <p class="text-body-2 mb-1 text-right">{{dayTraby(trabyInfo.time_need)}}</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="border" align="baseline">
              <v-col cols="6">
                <p class="mb-1 text-subtitle-2">Oferta:</p>
              </v-col>
              <v-col cols="6">
                <p class="text-body-2 mb-1 text-right">$ {{trabyInfo.price_init}}</p>
              </v-col>
            </v-row>
            <div v-if="trabyInfo.photos_traby.length > 0">
              <v-row no-gutters class>
                <v-col cols="12">
                  <p class="text-subtitle-2 my-2 text-center">Referencia Visual</p>
                </v-col>
              </v-row>
              <v-row no-gutters justify="space-between">
                <v-col
                  cols="3"
                  class="d-flex justify-center mx-1 mb-3"
                  v-for="(image,i) in trabyInfo.photos_traby"
                  :key="i"
                >
                  <v-img
                    height="50px"
                    width="50px"
                    :src="`storage/trabies/${trabyInfo.id}/${image.image}`"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  outlined
                  small
                  block
                  color="primary"
                  @click="dialogTraby = false"
                >Regresar a busqueda</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTrabyMessage" persistent max-width="400px">
        <v-card>
          <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="auto">
                <v-avatar size="36px">
                  <v-img
                    :src="traby.user != undefined ? `storage/avatar/${traby.user.id}/${traby.user.image}` :''"
                  />
                </v-avatar>
              </v-col>
              <v-col cols="auto">
                <p
                  class="ml-3 teal--text title ma-0"
                >{{traby.user != undefined ? traby.user.name : ''}} {{traby.user != undefined ? traby.user.last_name : ''}}</p>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="mt-4">
            <v-row no-gutters>
              <v-col cols="12">
                <p class="text-subtitle-2 ma-0">Descripcion:</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <p class="text-justify text-body-2 mb-0">{{traby.description}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  hide-details
                  label="Mensaje"
                  color="primary"
                  background-color="inputBg"
                  outlined
                  no-resize
                  v-model="message"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="counterOffer"
                  hide-details
                  label="Oferta"
                  number
                  color="primary"
                  background-color="inputBg"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row justify="space-between" class="mt-3" no-gutters>
              <v-col cols="5">
                <v-btn
                  outlined
                  small
                  block
                  color="primary"
                  @click="dialogTrabyMessage = false"
                >Regresar</v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn outlined small block color="primary" @click="sendMessage">Contactar</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      dialogTraby: false,
      dialogTrabyMessage: false,
      trabyInfo: {
        description: null,
        time_need: null,
        latitude_init: null,
        longitude_init: null,
        category: {
          description: null
        },
        price_traby: {
          price: null
        },
        photos_traby: []
      },
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }]
          }
        ]
      },
      trabies: [],
      trabers: [],
      traby: {
        description: null,
        time_need: null,
        latitude_init: null,
        longitude_init: null,
        category: {
          description: null
        },
        price_traby: {
          price: null
        },
        photos_traby: []
      },
      dialogTrabyMessage: false,
      message: null,
      counterOffer: null,
      searchLocal: null
    };
  },
  mounted() {
    this.$store.dispatch("setHidden");
    this.searchLocal = this.search;
    this.fetchAll();
  },
  methods: {
    async fetchAll() {
      try {
        const search = this.searchLocal || null;
        switch (this.typeUser) {
          case 1:
            this.getTrabers();
            break;
          case 2:
            this.getTrabies();
            break;
          case 3:
            this.getTrabers();
            this.getTrabies();
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goProfile(profileId) {
      this.$router
        .push({ name: "Profile", params: { id: profileId } })
        .catch(err => {});
    },
    async getTrabies() {
      const response = await axios.get(
        `api/trabySearchGlobal/${this.searchLocal}`
      );

      this.trabies = response.data.length > 0 ? response.data : [];
    },
    async getTrabers() {
      const response = await axios.get(
        `api/userSearchGlobal/${this.searchLocal}`
      );

      this.trabers = response.data.length > 0 ? response.data : [];
    },
    showInfoTraby(id) {
      axios.get(`api/traby/${id}`).then(response => {
        this.trabyInfo = response.data;
        this.dialogTraby = true;
      });
    },
    dayTraby(time) {
      return `${moment(time).format("lll")}`;
    },
    contactTraby(id) {
      axios.get(`api/user/data`).then(response => {
        if (response.data.state !== 0) {
          axios.get(`api/traby/${id}`).then(response => {
            this.traby = response.data;
            this.counterOffer = this.traby.price_init;
            this.dialogTrabyMessage = true;
          });
        } else {
          this.$swal({
            title: "Completa tu perfil",
            text: "Antes de continuar necesitamos que completes tu perfil",
            confirmButtonColor: "#00A4CB",
            confirmButtonText: "Completar"
          }).then(({ value }) => {
            if (value) {
              this.$router.push({ name: "Account" }).catch(err => {});
            }
          });
        }
      });
    },
    sendMessage() {
      axios
        .post(`api/message`, {
          traby_id: this.traby.id,
          give: this.traby.user.id,
          description: this.message,
          price: this.counterOffer
        })
        .then(response => {
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Hemos enviado tu mensaje.",
            "success"
          );
          this.dialogTrabyMessage = false;
          this.traby = {
            description: null,
            time_need: null,
            latitude_init: null,
            longitude_init: null,
            category: {
              description: null
            },
            photos_traby: []
          };
        });
    },
    since(time) {
      return moment(time).fromNow();
    }
  },
  beforeDestroy() {
    this.$store.dispatch("setSearchDefautl");
  },
  computed: {
    ...mapState({
      search: state => state.search.search,
      typeUser: state => state.auth.type
    }),
    titleSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-subtitle-2 font-weight-bold";
        case "sm":
          return "text-subtitle-1 font-weight-bold";
        default:
          return "title";
      }
    }
  }
};
</script>