export default {
    namespaced: false,
    state: {
        id: "",
        name: "",
        email: "",
        address: "",
        photoPerfil: "",
        latitude: "",
        longitude: "",
        parroquia_id: "",
        type: ""
    },
    mutations: {
        USER_AUTH(state, data) {
            state.id = data.id;
            state.name = data.name;
            state.email = data.email;
            state.address = data.address;
            state.photoPerfil = data.photoPerfil;
            state.longitude = data.longitude;
            state.latitude = data.latitude;
            state.parroquia_id = data.parroquia_id;
            state.type = data.type;
        },
        SET_PHOTO_PERFIL(state, data) {
            state.photoPerfil = data.photoPerfil;
        },
        SET_LAT_LONG(state, data) {
            state.latitude = data.latitude;
            state.longitude = data.longitude;
            state.address = data.address;
            state.parroquia_id = data.parroquia_id;
        }
    },
    actions: {
        userAuth(context, data) {
            context.commit("USER_AUTH", {
                id: data.id,
                name: data.name,
                email: data.email,
                address: data.address,
                photoPerfil: data.photoPerfil,
                longitude: data.longitude,
                latitude: data.latitude,
                parroquia_id: data.parroquia_id,
                type: data.type
            });
        },
        setPhotoPerfil(context, data) {
            context.commit("SET_PHOTO_PERFIL", {
                photoPerfil: data.photoPerfil
            });
        },
        setAddress(context, data) {
            context.commit("SET_LAT_LONG", {
                latitude: data.latitude,
                longitude: data.longitude,
                address: data.address,
                parroquia_id: data.parroquia_id
            });
        }
    }
};
