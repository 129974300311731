<template>
  <v-container grid-list-xs>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card outlined elevation="1">
          <v-stepper v-model="step" vertical elevation="1">
            <v-stepper-step step="1" editable color="teal" class="mb-n3">Detalles acerca del traby</v-stepper-step>
            <v-stepper-content step="1" class="my-0 py-0">
              <v-row class="mt-2">
                <v-col>
                  <v-alert
                    color="teal"
                    :value="true"
                    dense
                    class="white--text"
                  >En esta sección podrás ofertar un trabajo para otras personas, aquí encontrás la persona ideal para el trabajo que necesitas.</v-alert>
                </v-col>
              </v-row>
              <v-row align="baseline" dense class="mt-n7">
                <v-col cols="auto" class="py-0">
                  <p class="pr-3 mb-0 borderRight" v-text="'Publicar Traby por'" />
                </v-col>
                <v-col cols="auto" class="mb-2 mt-0 py-0">
                  <v-radio-group row v-model="giveType" @change="changeTypeTraby" hide-details>
                    <v-radio label="Habilidades" on-icon="fas fa-check-circle" :value="1" />
                    <v-radio label="Titulo Profesional" on-icon="fas fa-check-circle" :value="2" />
                    <v-radio label="Practicante" on-icon="fas fa-check-circle" :value="3" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-divider class="mb-2" />
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="title"
                    outlined
                    dense
                    label="Nombre de tu Publicacion"
                    color="primary"
                    background-color="inputBg"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="description"
                    outlined
                    dense
                    label="Descripcion"
                    color="primary"
                    background-color="inputBg"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-combobox
                    v-if="categoryItems.length > 0"
                    :items="categoryItems"
                    v-model="categorySelects"
                    item-text="description"
                    item-value="id"
                    label="Categoria de Habilidad"
                    outlined
                    dense
                    color="primary"
                    background-color="inputBg"
                    @change="changePrice"
                  />
                  <v-combobox
                    v-if="careerItems.length > 0"
                    :items="careerItems"
                    v-model="careerSelects"
                    item-text="description"
                    item-value="id"
                    label="Titulo profesional"
                    outlined
                    dense
                    color="primary"
                    background-color="inputBg"
                    @change="changePrice"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    label="Monto a pagar por el Traby"
                    color="primary"
                    background-color="inputBg"
                    v-model="price"
                    dense
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-dialog
                    ref="dialogDatePicker"
                    v-model="modalDatePicker"
                    :return-value.sync="date"
                    persistent
                    width="320px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="date"
                        dense
                        label="Fecha del Traby"
                        readonly
                        outlined
                        color="primary"
                        background-color="inputBg"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="date" :min="minDatePicker" locale="es">
                      <v-row no-gutters justify="space-around">
                        <v-col cols="auto">
                          <v-btn
                            outlined
                            small
                            color="primary"
                            @click="modalDatePicker = false"
                          >Regresar</v-btn>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            outlined
                            small
                            color="primary"
                            @click="$refs.dialogDatePicker.save(date)"
                          >Aceptar</v-btn>
                        </v-col>
                      </v-row>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-dialog
                    ref="dialogTimePicker"
                    v-model="modalTimePicker"
                    :return-value.sync="time"
                    persistent
                    width="320px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="time"
                        dense
                        label="Hora del Traby"
                        readonly
                        v-on="on"
                        outlined
                        color="primary"
                        background-color="inputBg"
                      />
                    </template>
                    <v-time-picker
                      v-if="modalTimePicker"
                      v-model="time"
                      :min="minTimePicker"
                      format="24hr"
                    >
                      <v-row no-gutters justify="space-around" class="mt-n2">
                        <v-col cols="auto">
                          <v-btn
                            outlined
                            small
                            color="primary"
                            @click="modalTimePicker = false"
                          >Regresar</v-btn>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            outlined
                            small
                            color="primary"
                            @click="$refs.dialogTimePicker.save(time)"
                          >Aceptar</v-btn>
                        </v-col>
                      </v-row>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <v-row dense>
                    <v-col cols="12">
                      <p class="mb-0" v-text="'Pago por'" />
                    </v-col>
                  </v-row>
                  <v-divider />

                  <v-row dense>
                    <v-col>
                      <v-radio-group v-model="pay" row class="mt-0" hide-details>
                        <v-radio label="Traby" on-icon="fas fa-check-circle" :value="0" />
                        <v-radio label="Horas" on-icon="fas fa-check-circle" :value="1" />
                        <v-radio label="Medio Tiempo" on-icon="fas fa-check-circle" :value="2" />
                        <v-radio label="T. Completo" on-icon="fas fa-check-circle" :value="3" />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row dense>
                    <v-col cols="12">
                      <p class="mb-0" v-text="'Ubicacion'" />
                    </v-col>
                  </v-row>
                  <v-divider />

                  <v-row dense>
                    <v-col>
                      <v-radio-group v-model="ubication" row class="mt-0" hide-details>
                        <v-radio label="Presencial" on-icon="fas fa-check-circle" :value="0" />
                        <v-radio label="Remoto" on-icon="fas fa-check-circle" :value="1" />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row justify="end" dense>
                <v-col cols="auto">
                  <v-btn outlined small color="primary" @click="step = 2">Siguiente</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-step step="2" editable color="teal">Ubicacion donde se realizara el traby</v-stepper-step>
            <v-stepper-content step="2">
              <v-row>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-combobox
                    :items="provinciasItems"
                    @change="changeProvincia"
                    v-model="provinciaSelect"
                    hide-details
                    item-text="provincia"
                    item-value="id"
                    label="Provincia"
                    outlined
                    color="primary"
                    background-color="inputBg"
                    dense
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
                  <v-combobox
                    :items="cantonesItems"
                    @change="changeCanton"
                    v-model="cantonSelect"
                    hide-details
                    item-text="canton"
                    item-value="id"
                    label="Cantón"
                    outlined
                    color="primary"
                    background-color="inputBg"
                    dense
                    :disabled="disabledCanton"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
                  <v-combobox
                    :items="parroquiasItems"
                    v-model="parroquiaSelect"
                    @change="changeParroquia"
                    hide-details
                    item-text="parroquia"
                    item-value="id"
                    label="Parroquia"
                    outlined
                    color="primary"
                    background-color="inputBg"
                    dense
                    :disabled="disabledParroquia"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Direccion"
                    color="primary"
                    hide-details
                    background-color="inputBg"
                    outlined
                    dense
                    ref="gmapAutocomplete"
                    v-model="address"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <GmapMap
                    ref="mapRef"
                    :center="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
                    :clickable="true"
                    :zoom="16"
                    :options="mapOptions"
                    @click="addMarker"
                    class="heightMap400"
                  >
                    <GmapMarker
                      icon="images/maker.png"
                      :clickable="true"
                      :draggable="true"
                      :position="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
                    />
                  </GmapMap>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn outlined small color="primary" @click="step = 1">Regresar</v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn outlined small color="primary" @click="step = 3">Siguiente</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-step step="3" editable color="teal">Danos una referencia visual (Opcional)</v-stepper-step>
            <v-stepper-content step="3">
              <v-row>
                <input
                  type="file"
                  class="d-none"
                  ref="image"
                  accept="image/*"
                  @change="onFilePicked"
                />
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <div
                    class="d-flex justify-center align-center selectImage"
                    @click="changePic"
                    v-if="imgRef[0] == null"
                  >
                    <v-icon size="48" color="grayAlt">far fa-images</v-icon>
                    <p class="ma-0 mt-1 grayAlt--text">Cargar Fotos</p>
                  </div>
                  <div class="d-flex justify-center align-center" v-if="imgRef[0]">
                    <v-hover v-slot:default="{ hover }">
                      <v-img height="150px" width="150px" :src="imgRef[0]" @click="deleteImage(0)">
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex justify-center align-center transition-fast-in-fast-out hoverDeletePic"
                          >
                            <v-icon color="white" size="36">fas fa-trash</v-icon>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </div>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <div
                    class="d-flex justify-center align-center selectImage"
                    @click="changePic"
                    v-if="imgRef[1] == null"
                  >
                    <v-icon size="48" color="grayAlt">far fa-images</v-icon>
                    <p class="ma-0 mt-1 grayAlt--text">Cargar Fotos</p>
                  </div>
                  <div class="d-flex justify-center align-center" v-if="imgRef[1]">
                    <v-hover v-slot:default="{ hover }">
                      <v-img height="150px" width="150px" :src="imgRef[1]" @click="deleteImage(1)">
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex justify-center align-center transition-fast-in-fast-out hoverDeletePic"
                          >
                            <v-icon color="white" size="36">fas fa-trash</v-icon>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </div>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <div
                    class="d-flex justify-center align-center selectImage"
                    @click="changePic"
                    v-if="imgRef[2] == null"
                  >
                    <v-icon size="48" color="grayAlt">far fa-images</v-icon>
                    <p class="ma-0 mt-1 grayAlt--text">Cargar Fotos</p>
                  </div>
                  <div class="d-flex justify-center align-center" v-if="imgRef[2]">
                    <v-hover v-slot:default="{ hover }">
                      <v-img height="150px" width="150px" :src="imgRef[2]" @click="deleteImage(2)">
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex justify-center align-center transition-fast-in-fast-out hoverDeletePic"
                          >
                            <v-icon color="white" size="36">fas fa-trash</v-icon>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn outlined small color="primary" @click="step = 2">Regresar</v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn outlined small color="primary" @click="dialogTraby = true">Ver resumen</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTraby" persistent max-width="400px">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text text-h6 ma-0">Resumen del Traby</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row dense justify="center" class="mt-2">
            <v-col cols="auto">
              <p class="text-subtitle-2 mb-2 text-uppercase">{{title !== null ? title : ''}}</p>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <GmapMap
                ref="mapRef"
                :center="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
                :clickable="true"
                :zoom="19"
                :options="mapOptionsResume"
                class="heightMap150"
              >
                <GmapMarker
                  icon="images/maker.png"
                  :clickable="true"
                  :draggable="true"
                  :position="{ lat: Number(this.latitude), lng: Number(this.longitude) }"
                />
              </GmapMap>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <p class="text-subtitle-2 ma-0">Descripcion:</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border">
            <v-col>
              <p class="text-justify text-body-2 mb-1">{{description !== null ? description : ''}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p class="mb-1 text-subtitle-2">{{categorySelects ? 'Categoria:' : 'Profesion:'}}</p>
            </v-col>
            <v-col cols="6">
              <p
                class="text-body-2 mb-1 text-right"
              >{{categorySelects ? categorySelects.description : careerSelects ? careerSelects.description : ''}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline" justify="space-between">
            <v-col cols="auto">
              <p class="mb-1 text-subtitle-2">Fecha y Hora:</p>
            </v-col>
            <v-col cols="auto">
              <p
                class="text-body-2 mb-1 text-right"
              >{{`${date ? `${dayTrabyFormat(`${date} ${time}`)}` : ''}`}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p class="mb-1 text-subtitle-2">Oferta:</p>
            </v-col>
            <v-col cols="6">
              <p class="text-body-2 mb-1 text-right">$ {{`${price ? `${price}` : ''}`}}</p>
            </v-col>
          </v-row>

          <div v-if="imgRef.length > 0">
            <v-row no-gutters class>
              <v-col cols="12">
                <p class="text-subtitle-2 my-2 text-center">Referencia Visual</p>
              </v-col>
            </v-row>
            <v-row no-gutters justify="space-between">
              <v-col
                cols="3"
                class="d-flex justify-center mx-1 mb-3"
                v-for="item in imgRef"
                :key="item"
              >
                <v-img height="50px" width="50px" :src="item" />
              </v-col>
            </v-row>
          </div>
          <v-row justify="space-around" class="mt-2" dense>
            <v-col cols="auto">
              <v-btn
                outlined
                small
                block
                color="primary"
                @click="dialogTraby = false"
              >Volver a traby</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn outlined small block color="primary" @click="saveTraby">Publicar Traby</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
moment.locale("es");
export default {
  data() {
    return {
      typeTraby: null,
      dialogTraby: false,
      address: "",
      latitude: 0,
      longitude: 0,
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      mapOptionsResume: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        disableDefaultUI: true,
        draggable: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      parroquiaTraby: null,
      provinciasItems: [],
      cantonesItems: [],
      parroquiasItems: [],
      provinciaSelect: null,
      cantonSelect: null,
      parroquiaSelect: null,
      step: 1,
      categoryItems: [],
      careerItems: [],
      categorySelects: null,
      careerSelects: null,
      title: null,
      description: null,
      pay: 0,
      ubication: 0,
      modalDatePicker: false,
      date: null,
      modalTimePicker: false,
      time: null,
      price: 0,
      imgRef: [],
      disabledCanton: true,
      disabledParroquia: true,
      giveType: 1,
    };
  },
  created() {
    this.formatAutocomplete();
    this.initMap();
    this.getAll();
  },
  methods: {
    formatAutocomplete() {
      this.$gmapApiPromiseLazy().then(() => {
        let gmapAutocompletePlace = new google.maps.places.Autocomplete(
          this.$refs.gmapAutocomplete.$refs.input
        );

        gmapAutocompletePlace.setComponentRestrictions({ country: ["ec"] });

        gmapAutocompletePlace.addListener("place_changed", (e) => {
          let place = gmapAutocompletePlace.getPlace();
          this.address = place.name;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    },
    changeTypeTraby(e) {
      switch (e) {
        case 1:
          axios.get(`api/category`).then((response) => {
            this.careerItems = [];
            this.careerSelects = null;
            this.categoryItems = response.data;
          });
          break;
        case 2:
        case 3:
          axios.get(`api/career`).then((response) => {
            this.categoryItems = [];
            this.categorySelects = null;
            this.careerItems = response.data;
          });
          break;
      }
    },
    addMarker(event) {
      this.latitude = event.latLng.lat();
      this.longitude = event.latLng.lng();
    },
    initMap() {
      this.latitude = this.latitudeUser;
      this.longitude = this.longitudeUser;
    },
    changePic() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        let image = files[0].name;
        if (image.lastIndexOf(".") <= 0) return;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.onloadend = () => {
          this.imgRef.push(fileReader.result);
        };
      }
    },
    deleteImage(item) {
      this.imgRef.splice(item, 1);
    },
    changePrice() {
      this.price =
        this.categoryItems.length > 0
          ? this.categorySelects.price_top
          : this.careerSelects.price_top;
    },
    saveTraby() {
      console.log();
      let trabiesable_id = null;

      if (this.categoryItems.length > 0) {
        trabiesable_id = this.categorySelects ? this.categorySelects.id : 0;
      } else {
        trabiesable_id = this.careerSelects ? this.careerSelects.id : 0;
      }

      axios
        .post("api/traby", {
          trabiesable_id: trabiesable_id,
          trabiesable_type: this.giveType,
          title: this.title,
          description: this.description,
          pay: this.pay,
          ubication: this.ubication,
          address: this.address,
          latitude_init: this.latitude,
          longitude_init: this.longitude,
          time_need: `${this.date} ${this.time}:00`,
          parroquia_id: this.parroquiaTraby,
          images: this.imgRef,
          price_init: this.price,
        })
        .then((response) => {
          Object.assign(this.$data, this.$options.data.apply(this));
          this.initMap();
          this.getAll();
          this.$swal(
            "Traby Publicado",
            "Gracias por confiar en nosotros, pronto conseguirás mano de obra calificada para tu traby.",
            "success"
          );
        })
        .catch((error) => {
          this.dialogTraby = false;
          this.$swal("Error", "Verifique Datos ingreados", "error");
        });
    },
    getAll() {
      axios.get(`api/provincias`).then((response) => {
        this.provinciasItems = response.data;
        axios.get(`api/parroquia/id/${this.parroquiaUser}`).then((response) => {
          this.parroquiaTraby = this.parroquiaUser;
          this.parroquiaSelect = response.data.parroquia;
          this.cantonSelect = response.data.canton.canton;
          this.provinciaSelect = response.data.canton.provincia.provincia;
          this.address = this.addressUser;
          axios.get(`api/category`).then((response) => {
            this.categoryItems = response.data;
          });
        });
      });
    },
    changeProvincia(e) {
      axios.get(`api/canton/${e.id}`).then((response) => {
        this.disabledCanton = false;
        this.cantonesItems = response.data;
      });
    },
    changeCanton(e) {
      axios.get(`api/parroquia/${e.id}`).then((response) => {
        this.disabledParroquia = false;
        this.parroquiasItems = response.data;
      });
    },
    changeParroquia(e) {
      this.parroquiaTraby = e.id;
    },
    dayTrabyFormat(time) {
      return `${moment(time).format("lll")}`;
    },
  },
  computed: {
    ...mapState({
      latitudeUser: (state) => state.auth.latitude,
      longitudeUser: (state) => state.auth.longitude,
      parroquiaUser: (state) => state.auth.parroquia_id,
      addressUser: (state) => state.auth.address,
    }),
    minTimePicker() {
      return moment(this.date, "YYYY/MM/DD").isAfter()
        ? "00:00"
        : moment(new Date().toISOString()).format("LT");
    },
    minDatePicker() {
      return moment(new Date().toISOString()).format("YYYY-MM-DD");
    },
  },
};
</script>