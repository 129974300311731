<template>
  <div>
    <v-app-bar
      flat
      color="appBar"
      app
      :height="$vuetify.breakpoint.name !== 'xs' ? '60px' : '50px'"
      class="elevationTeal1"
    >
      <div>
        <v-img
          src="images/logoTeal.svg"
          class="cursosrPointer"
          position
          contain
          width="150px"
          :height="$vuetify.breakpoint.name !== 'xs' ? '50px' : '40px'"
          @click="route('Home')"
        />
      </div>
      <div>
        <v-text-field
          :clearable="false"
          max-width="50px"
          v-model="searchGlobal"
          hide-details
          rounded
          background-color="inputBg"
          placeholder="Buscar en traby"
          v-if="$vuetify.breakpoint.width > 899 && hiddenSearch"
          @keyup.enter="initSearch"
        >
          <template v-slot:prepend-inner>
            <template>
              <v-icon color="teal" class="mt-1" left size="16">fa fa-search</v-icon>
            </template>
          </template>
        </v-text-field>
      </div>

      <v-spacer />
      <v-row justify="end" align="baseline">
        <v-col cols="auto" v-if="$vuetify.breakpoint.name !== 'xs' && typeUser !== 2">
          <v-btn
            color="teal"
            class="white--text"
            depressed
            elevation="1"
            small
            @click="route('GiveTraby')"
          >
            <v-icon color="white" left size="20">far fa-newspaper</v-icon>Publicar Traby
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="$vuetify.breakpoint.name !== 'xs'">
          <v-badge color="error" overlap :content="31" :value="false">
            <v-icon>far fa-comment-alt</v-icon>
          </v-badge>
        </v-col>
        <v-col cols="auto" v-if="$vuetify.breakpoint.name !== 'xs'">
          <v-badge color="error" overlap :content="20" :value="false">
            <v-icon>far fa-bell</v-icon>
          </v-badge>
        </v-col>
        <v-col cols="auto" v-if="$vuetify.breakpoint.name !== 'xs'">
          <v-icon>far fa-question-circle</v-icon>
        </v-col>

        <v-col cols="auto" class="mr-n5" v-if="$vuetify.breakpoint.name !== 'xs'">
          <v-avatar size="35">
            <v-img
              class="cursosrPointer"
              @click="route('Profile')"
              :src="picPerfil ? `storage/avatar/${id}/${picPerfil}` : 'images/incognita.jpg' "
            />
          </v-avatar>
        </v-col>
        <v-col cols="auto">
          <v-menu
            nudge-bottom="11"
            v-model="menu"
            close-on-content-click
            offset-y
            bottom
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon color="primary" small v-on="on">
                <v-icon>{{$vuetify.breakpoint.name !== 'xs' ? 'fas fa-angle-down' : 'fas fa-bars'}}</v-icon>
              </v-btn>
            </template>

            <v-card width="250px">
              <v-list class="pa-0 py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img
                      :src="picPerfil ? `storage/avatar/${id}/${picPerfil}` : 'images/incognita.jpg' "
                    />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{name}}</v-list-item-title>
                    <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider />

              <v-list dense class="py-0">
                <div v-for="(menu, i) in menus" :key="i">
                  <template v-if="menu.hidden !== typeUser">
                    <v-list-item @click="route(menu.route)">
                      <v-list-item-icon class="mr-0 widthIconMenu">
                        <v-icon color="teal" v-text="menu.icon" size="20" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="menu.text" />
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="i !== 7" />
                  </template>
                </div>
              </v-list>

              <v-divider />
              <v-card-actions class="d-flex justify-end py-0">
                <v-btn
                  color="primary"
                  small
                  text
                  @click="$vuetify.theme.dark = !$vuetify.theme.dark"
                >
                  <v-icon
                    color="teal"
                    small
                    size="14"
                    left
                  >{{$vuetify.theme.dark == true ? 'far fa-sun' : 'far fa-moon'}}</v-icon>
                  Modo {{$vuetify.theme.dark == true ? 'Claro' : 'Oscuro'}}
                </v-btn>
                <v-btn color="primary" small text @click="logout">
                  Salir
                  <v-icon color="teal" class="ml-2" small size="14">far fa-share-square</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menu: false,
      menuSearch: false,
      searchGlobal: null,
      menus: [
        {
          icon: "far fa-edit",
          text: "Mi Cuenta",
          route: "Account",
          hidden: 0
        },
        {
          icon: "far fa-user",
          text: "Mi Perfil",
          route: "Profile",
          hidden: 0
        },
        {
          icon: "far fa-newspaper",
          text: "Publicar Traby",
          route: "GiveTraby",
          hidden: 2
        },
        {
          icon: "fas fa-search-dollar",
          text: "Buscar Traby",
          route: "SearchTraby",
          hidden: 1
        },
        {
          icon: "fas fa-search",
          text: "Buscar Traber",
          route: "SearchTraber",
          hidden: 2
        },
        {
          icon: "fas fa-chart-bar",
          text: "Traby Controles",
          route: "TrabyControl",
          hidden: 0
        },
        {
          icon: "far fa-comment-alt",
          text: "Mensajes",
          route: "Message",
          hidden: 0
        },
        {
          icon: "far fa-calendar-alt",
          text: "Calendario",
          route: "Calendar",
          hidden: 1
        }
      ]
    };
  },
  methods: {
    route(ruta) {
      if (ruta == "Profile") {
        this.$router
          .push({ name: ruta, params: { id: this.id } })
          .catch(err => {});
      } else {
        this.$router.push({ name: ruta }).catch(err => {});
      }
    },
    logout() {
      axios.post("api/logout", {}).then(response => {
        axios.defaults.headers.common["Authorization"] = "";
        localStorage.clear();
        sessionStorage.clear();
        this.$router.push({ name: "Login" }).catch(err => {});
      });
    },
    initSearch() {
      this.$store.dispatch("setSearch", {
        search: this.searchGlobal
      });
      this.searchGlobal = null;
      this.$router.push({ name: "GlobalSearch" }).catch(err => {});
    }
  },
  computed: {
    ...mapState({
      id: state => state.auth.id,
      name: state => state.auth.name,
      email: state => state.auth.email,
      photoPerfil: state => state.auth.photoPerfil,
      typeUser: state => state.auth.type,
      hiddenSearch: state => state.search.hidden
    }),
    picPerfil() {
      return this.photoPerfil === "" ? null : this.photoPerfil;
    }
  }
};
</script>

<style>
.point.v-card {
  margin-top: 10px;
  position: relative;
}

.point.v-card::after {
  content: "";
  position: absolute;

  width: 0;
  height: 0;
  text-shadow: 1px 1px red;

  border: 10px solid #f7fdff;

  border-bottom-color: #d6d6d6;
  left: 90%;
  bottom: 100%;
}
</style>