<template>
  <v-layout row wrap>
    <v-flex>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout row>
          <v-flex>
            <v-text-field
              outlined
              label="NOMBRES"
              append-icon="far fa-user"
              color="primary"
              v-model="name"
              :error-messages="errors.name"
              :rules="rules.name"
              dense
              :hide-details="!errors.name"
              background-color="inputBg"
              class="mb-2"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex>
            <v-text-field
              outlined
              label="APELLIDOS"
              append-icon="far fa-user"
              color="primary"
              v-model="last_name"
              :error-messages="errors.last_name"
              :rules="rules.last_name"
              dense
              :hide-details="!errors.last_name"
              background-color="inputBg"
              class="mb-2"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex>
            <v-text-field
              outlined
              label="CORREO"
              append-icon="fa fa-at"
              color="primary"
              v-model="email"
              :error-messages="errors.email"
              :rules="rules.email"
              dense
              :hide-details="!errors.email"
              background-color="inputBg"
              class="mb-2"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex>
            <v-text-field
              outlined
              v-model="password"
              append-icon="fa fa-eye-slash"
              :rules="rules.password"
              color="primary"
              type="password"
              :error-messages="errors.password"
              label="CONTRASEÑA"
              dense
              :hide-details="!errors.password"
              background-color="inputBg"
              class="mb-2"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-alert
              v-model="alertError"
              type="error"
              icon="far fa-times-circle"
            >Verifique los datos ingresados.</v-alert>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-alert
              v-model="alertSuccess"
              type="success"
              prominent
              color="teal"
              icon="far fa-check-circle"
            >Registro exitoso, verifique su correo para continuar.</v-alert>
          </v-flex>
        </v-layout>
        <v-layout row justify-space-around>
          <v-flex xs12 class="mb-2">
            <v-btn
              :loading="loadingButtons"
              small
              block
              outlined
              color="primary"
              @click="register"
            >Registrarse</v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      loadingButtons: false,
      showPassword: false,
      valid: true,
      name: "",
      last_name: "",
      email: "",
      password: "",
      errors: [],
      alertError: false,
      alertSuccess: false,
      rules: {
        name: [
          value => !!value || "El campo nombre es Requerido",
          value => {
            const pattern = /^[a-zA-ZÀ-ÿñÑ\s]+$/;
            return (
              pattern.test(value) ||
              `
              Debe Contener:<br>
              - Un minimo de 3 Digitos<br>
              - Solo debe contener digitos que esten comprendidos entre A-Z`
            );
          }
        ],
        last_name: [
          value => !!value || "El campo nombre es Requerido",
          value => {
            const pattern = /^[a-zA-ZÀ-ÿñÑ\s]+$/;
            return (
              pattern.test(value) ||
              `
              Debe Contener:<br>
              - Un minimo de 5 Digitos<br>
              - Solo debe contener digitos que esten comprendidos entre A-Z`
            );
          }
        ],
        email: [
          value => !!value || "El campo correo es Requerido",
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) || "El campo no es considerado como correo"
            );
          }
        ],
        password: [
          value => !!value || "El campo contraseña es Requerido",
          value => {
            const pattern = /^[A-Za-z\d]{8,15}/;
            return pattern.test(value) || `Debe Contener: De 8 a 15 Digitos`;
          }
        ]
      }
    };
  },
  methods: {
    register() {
      this.loadingButtons = true;
      axios
        .post("api/register", {
          name: this.name,
          last_name: this.last_name,
          email: this.email,
          password: this.password
        })
        .then(response => {
          this.alertSuccess = true;
          this.alertError = false;
          this.errors = [];
          this.name = "";
          this.email = "";
          this.password = "";
          this.$refs.form.reset();
          this.valid = true;
          this.loadingButtons = false;
        })
        .catch(error => {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              this.alertSuccess = false;
              this.alertError = true;
              break;
            default:
              break;
          }
          this.loadingButtons = false;
        });
    }
  }
};
</script>