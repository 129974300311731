<template>
  <v-row justify="center" no-gutters class="mt-3">
    <v-col cols="12" sm="9">
      <v-card outlined flat elevation="1" class="pa-3">
        <v-row no-gutters v-if="skills.length <= 0">
          <v-col cols="12">
            <v-alert color="teal" class="white--text font-weight-bold">
              <v-icon color="white" left>fa fa-info-circle</v-icon>Para ayudarte a conectar con un trabajo necesitamos que ingreses tus habilidades.
            </v-alert>
          </v-col>
        </v-row>
        <div v-if="!typeView">
          <v-row dense justify="space-between">
            <v-col cols="auto">
              <p class="mb-0" v-text="'Listado Habilidades'" />
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" outlined small @click="typeView = true">Agregar</v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row dense>
            <v-col cols="12">
              <v-simple-table class="tableShadow" :light="$vuetify.theme.dark">
                <thead>
                  <tr class="white">
                    <th class="text-center">Descripcion</th>
                    <th width="30%" class="text-center">Categoria</th>
                    <th class="text-center">Experiencia</th>
                    <th class="text-center">Herramienta</th>
                    <th class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in skills" :key="item.description">
                    <td class="text-center">{{ item.skill }}</td>
                    <td>
                      <v-row align="center" no-gutters>
                        <v-col cols="auto">
                          <p class="mb-0">{{item.category.description}}</p>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="text-center">
                      <v-icon
                        size="20"
                        :class="item.experience == 1 ? '' : item.experience == 2 ? 'warning--text' : 'teal--text'"
                      >{{item.experience == 1 ? 'fas fa-battery-quarter' : item.experience == 2 ? 'fas fa-battery-half' : 'fas fa-battery-full'}}</v-icon>
                    </td>
                    <td class="text-center">
                      <v-icon
                        size="20"
                        :class="item.tool ? 'teal--text' : '' "
                      >{{ item.tool ? 'far fa-check-circle' : 'far fa-times-circle' }}</v-icon>
                    </td>
                    <td class="text-center">
                      <v-btn color="error" small @click="deleteSkill(item.id)" icon>
                        <v-icon>far fa-trash-alt</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
        <div v-if="typeView">
          <v-row dense>
            <v-col cols="12">
              <p class="mb-0" v-text="'Informacion de la Habilidad'" />
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="description"
                :error-messages="errors.skill"
                :rules="rules.skill"
                label="Descripcion"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-combobox
                :items="categoryItems"
                :error-messages="errors.category_id"
                :rules="rules.category_id"
                v-model="categorySelect"
                item-text="description"
                item-value="id"
                label="Categoria de Habilidad"
                color="primary"
                background-color="inputBg"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="additional"
                :error-messages="errors.additional"
                :rules="rules.additional"
                label="Elemento Diferenciador"
                color="primary"
                background-color="inputBg"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col>
              <v-row class="border" dense>
                <v-col cols="12">
                  <p class="mb-0" v-text="'Herramientas de Trabajo'" />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-radio-group
                    v-model="tool"
                    class="mt-0"
                    row
                    :error-messages="errors.tool"
                    :rules="rules.tool"
                  >
                    <v-radio
                      color="primary"
                      label="Poseo Heramientas"
                      on-icon="fas fa-check-circle"
                      value="1"
                    />
                    <v-radio
                      color="primary"
                      label="No Poseo Herramientas"
                      on-icon="fas fa-check-circle"
                      value="0"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="border" dense>
                <v-col cols="12">
                  <p class="mb-0" v-text="'Experiencia para Ejecutar'" />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-radio-group
                    class="mt-0"
                    v-model="experience"
                    row
                    :error-messages="errors.experience"
                    :rules="rules.experience"
                  >
                    <v-radio
                      on-icon="fas fa-check-circle"
                      label="Principiante"
                      value="1"
                      color="primary"
                    />
                    <v-radio
                      on-icon="fas fa-check-circle"
                      label="Intermedio"
                      value="2"
                      color="primary"
                    />
                    <v-radio
                      on-icon="fas fa-check-circle"
                      label="Avanzado"
                      value="3"
                      color="primary"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="end" dense>
            <v-col cols="auto" class="mr-3">
              <v-btn color="primary" outlined small @click="typeView = false">Regresar</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" outlined small @click="addSkill">Agregar Habilidad</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      typeView: false,
      description: "",
      categoryItems: [],
      categorySelect: null,
      tool: null,
      additional: null,
      experience: null,
      skills: [],
      errors: [],
      rules: {
        skill: [value => !!value || "El campo descripcion es Requerido"],
        category_id: [value => !!value || "El campo categoria es Requerido"],
        tool: [value => !!value || "El campo herramienta es Requerido"],
        experience: [value => !!value || "El campo experiencia es Requerido"],
        additional: [
          value => !!value || "El campo elemento diferenciador es Requerido"
        ]
      }
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    addSkill() {
      axios
        .post(`api/skill`, {
          skill: this.description,
          category_id: this.categorySelect == null ? 0 : this.categorySelect.id,
          tool: this.tool,
          experience: this.experience,
          additional: this.additional
        })
        .then(response => {
          Object.assign(this.$data, this.$options.data.apply(this));
          this.getAll();
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Hemos registrado tu nueva habilidad.",
            "success"
          );
          this.typeView = false;
          this.$emit("getData");
        })
        .catch(error => {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              this.$swal("Error", "Verifique Datos ingreados", "error");
              break;
          }
        });
    },
    changeCategories(e) {
      switch (e) {
        case "1":
          axios.get(`api/category`).then(response => {
            this.categoryItems = response.data;
          });
          break;
        case "2":
          axios.get(`api/category`).then(response => {
            this.categoryItems = response.data;
          });
          break;
      }
    },
    getAll() {
      axios.get(`api/category`).then(response => {
        this.categoryItems = response.data;
        axios.get(`api/skill`).then(response => {
          this.skills = response.data;
        });
      });
    },
    deleteSkill(id) {
      axios.delete(`api/skill/${id}`).then(response => {
        this.$swal(
          "Operación Exitosa",
          "Habilidad eliminada con exito.",
          "success"
        );
        this.getAll();
        this.$emit("getData");
      });
    }
  },
  computed: {
    ...mapState({
      id: state => state.auth.id
    })
  }
};
</script>