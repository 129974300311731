<template>
  <div>
    <v-row class="border mt-4" no-gutters>
      <v-col cols="12">
        <p class="mb-0" v-text="'Informacion Personal'" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="pb-0">
        <v-text-field
          outlined
          label="Nombre"
          color="primary"
          background-color="inputBg"
          v-model="name"
          :rules="rules.name"
          :error-messages="errors.name"
          dense
        />
      </v-col>
      <v-col cols="12" sm="6" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
        <v-text-field
          outlined
          label="Apellido"
          color="primary"
          background-color="inputBg"
          v-model="last_name"
          :rules="rules.last_name"
          :error-messages="errors.last_name"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="border" no-gutters>
      <v-col cols="12">
        <p class="mb-0" v-text="'Informacion de Contacto'" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
        <v-text-field
          v-model="phone"
          :rules="rules.phone"
          :error-messages="errors.phone"
          label="Telefono Movil"
          color="primary"
          background-color="inputBg"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" sm="2" class="pb-0">
        <v-combobox
          :items="flags"
          v-model="codeCountrySelect"
          label="Pais"
          color="primary"
          background-color="inputBg"
          item-text="description"
          item-value="id"
          outlined
          dense
        >
          <template slot="item" slot-scope="data">
            <v-avatar size="30" class="mr-5">
              <v-img :src="`images/flags/${data.item.image}`" />
            </v-avatar>
            {{data.item.description}}
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" sm="5" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
        <v-text-field
          v-model="whatsapp"
          :rules="rules.whatsapp"
          :error-messages="errors.whatsapp"
          :prefix="`(+${codeCountrySelect.code})`"
          label="WhatsApp"
          color="primary"
          background-color="inputBg"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row class="border" no-gutters>
      <v-col cols="12">
        <p class="mb-0" v-text="'Informacion de Domicilio'" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" class="pb-0">
        <v-combobox
          :items="provinciasItems"
          @change="changeProvincia"
          v-model="provinciaSelect"
          item-text="provincia"
          item-value="id"
          label="Provincia"
          outlined
          color="primary"
          background-color="inputBg"
          dense
        ></v-combobox>
      </v-col>
      <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
        <v-combobox
          :items="cantonesItems"
          @change="changeCanton"
          v-model="cantonSelect"
          item-text="canton"
          item-value="id"
          label="Cantón"
          outlined
          color="primary"
          background-color="inputBg"
          dense
          :disabled="disabledCanton"
        ></v-combobox>
      </v-col>
      <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xs ? 'py-0' : 'pb-0'">
        <v-combobox
          :items="parroquiasItems"
          v-model="parroquiaSelect"
          @change="changeParroquia"
          item-text="parroquia"
          item-value="id"
          label="Parroquia"
          outlined
          color="primary"
          background-color="inputBg"
          dense
          :disabled="disabledParroquia"
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          auto-grow
          rows="1"
          outlined
          label="Direccion"
          color="primary"
          background-color="inputBg"
          v-model="address"
          :rules="rules.address"
          :error-messages="errors.address"
        />
      </v-col>
    </v-row>
    <v-row justify="end" no-gutters>
      <v-col cols="auto" class="mr-3">
        <v-btn small color="primary" outlined @click="returnList">Regresar</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn small color="primary" outlined @click="update">Actualizar Referencia</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      last_name: null,
      whatsapp: null,
      phone: null,
      provinciaSelect: null,
      cantonSelect: null,
      parroquiaSelect: null,
      address: null,
      provinciasItems: [],
      flags: [],
      disabledCanton: true,
      cantonesItems: [],
      disabledParroquia: true,
      parroquiasItems: [],
      codeCountrySelect: {
        id: 4,
        description: "Ecuador",
        image: "ecuador.jpg",
        code: "593"
      },
      errors: [],
      rules: {
        name: [value => !!value || "El campo usuario es Requerido"],
        last_name: [value => !!value || "El campo usuario es Requerido"],
        phone: [value => !!value || "El campo usuario es Requerido"],
        whatsapp: [
          value => !!value || "El campo usuario es Requerido",
          value =>
            /^[1-9]\d{8,10}$/.test(value) || "El numero no puede iniciar en 0"
        ],
        address: [value => !!value || "El campo usuario es Requerido"]
      }
    };
  },
  props: {
    idEdit: 0
  },
  mounted() {
    this.getAll();
  },
  methods: {
    async getAll() {
      try {
        const provincias = await axios.get(`api/provincias`);
        const codeCountries = await axios.get(`api/codeCountries`);
        const reference = await axios.get(`api/referenceOne/${this.idEdit}`);

        this.provinciasItems = provincias.data;
        this.flags = codeCountries.data;

        this.name = reference.data.name;
        this.last_name = reference.data.last_name;
        this.whatsapp = reference.data.whatsapp;
        this.phone = reference.data.phone;
        this.address = reference.data.address;
        this.parroquiaSelect = reference.data.parroquia.parroquia;
        this.parroquiaReference = reference.data.parroquia.id;
        this.cantonSelect = reference.data.parroquia.canton.canton;
        this.provinciaSelect =
          reference.data.parroquia.canton.provincia.provincia;
      } catch {
        console.error("Error en peticion");
      }
    },
    changeProvincia(e) {
      axios.get(`api/canton/${e.id}`).then(response => {
        this.disabledCanton = false;
        this.cantonesItems = response.data;
      });
    },
    changeCanton(e) {
      axios.get(`api/parroquia/${e.id}`).then(response => {
        this.disabledParroquia = false;
        this.parroquiasItems = response.data;
      });
    },
    changeParroquia(e) {
      this.parroquiaReference = e.id;
    },
    update(userId) {
      axios
        .put(`api/reference/${this.idEdit}`, {
          name: this.name,
          last_name: this.last_name,
          phone: this.phone,
          whatsapp: this.whatsapp,
          parroquia_id: this.parroquiaReference,
          address: this.address
        })
        .then(response => {
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Hemos enviado tu solicitud de referencia",
            "success"
          );
          this.returnList();
        })
        .catch(error => {
          switch (error.response.status) {
            case 400:
              this.$swal(
                "error",
                "Solo puedes tener un máximo de 2 referencias",
                "error"
              );
              break;
            case 422:
              this.errors = error.response.data.errors;
              this.$swal("Error", "Verifique Datos ingreados", "error");
              break;
          }
        });
    },
    returnList() {
      this.name = null;
      this.last_name = null;
      this.whatsapp = null;
      this.phone = null;
      this.provinciaSelect = null;
      this.cantonSelect = null;
      this.parroquiaReference = null;
      this.address = null;
      this.provinciasItems = [];
      this.flags = [];
      this.disabledCanton = true;
      this.cantonesItems = [];
      this.disabledParroquia = true;
      this.parroquiasItems = [];
      this.codeCountrySelect = {
        id: 4,
        description: "Ecuador",
        image: "ecuador.jpg",
        code: "593"
      };
      this.$emit("returnList");
    }
  }
};
</script>