<template>
  <v-row justify="center" no-gutters class="mt-3">
    <v-col cols="12" sm="9">
      <v-card outlined flat elevation="1" class="pa-3">
        <v-row no-gutters v-if="referencesPersonal.length <= 0 && referencesWork.length <= 0">
          <v-col cols="12">
            <v-alert color="teal" class="white--text font-weight-bold">
              <v-icon color="white" left>fa fa-info-circle</v-icon>Para inspirar mas confianza en esta seccion puedes dar a conocer tus referencias tanto laborales como personales.
            </v-alert>
          </v-col>
        </v-row>
        <div v-if="typeView === 0">
          <v-row dense justify="space-between">
            <v-col cols="auto">
              <p class="mb-0" v-text="'Listados de Referencias'" />
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" outlined small @click="typeView = 1">Agregar</v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-5" />
          <v-row dense justify="space-between">
            <v-col cols="auto">
              <p class="mb-0" v-text="'Personales'" />
            </v-col>
          </v-row>
          <v-divider />
          <v-row dense>
            <v-col cols="12">
              <v-simple-table class="tableShadow" :light="$vuetify.theme.dark">
                <thead>
                  <tr class="white">
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Correo</th>
                    <th class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in referencesPersonal" :key="item.id">
                    <td
                      class="text-center cursosrPointer"
                      @click="getProfile(item.reference == null ? 0 : item.reference.id)"
                    >{{ item.user_reference ? `${item.reference.name} ${item.state == 1 ? '' : '(Pendiente de Confirmacion)'}` : item.name }}</td>
                    <td
                      class="text-center"
                    >{{ item.user_reference ? item.reference.email : item.email }}</td>
                    <td class="d-flex justify-center align-center">
                      <v-btn color="error" small icon @click="deleteReference(item.id)">
                        <v-icon size="20">far fa-trash-alt</v-icon>
                      </v-btn>
                      <v-btn class="ml-5" color="teal" small icon @click="editReference(item.id)">
                        <v-icon size="20">far fa-edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense justify="space-between" class="mt-5">
            <v-col cols="auto">
              <p class="mb-0" v-text="'Laborales'" />
            </v-col>
          </v-row>
          <v-divider />
          <v-row dense>
            <v-col cols="12">
              <v-simple-table class="tableShadow" :light="$vuetify.theme.dark">
                <thead>
                  <tr class="white">
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Correo</th>
                    <th class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in referencesWork" :key="item.id">
                    <td
                      class="text-center cursosrPointer"
                      @click="getProfile(item.reference == null ? 0 : item.reference.id)"
                    >{{ item.user_reference ? `${item.reference.name} ${item.state == 1 ? '' : '(Pendiente de Confirmacion)'}` : item.name }}</td>
                    <td
                      class="text-center"
                    >{{ item.user_reference ? item.reference.email : item.email }}</td>
                    <td class="d-flex justify-center align-center">
                      <v-btn color="error" small icon @click="deleteReference(item.id)">
                        <v-icon size="20">far fa-trash-alt</v-icon>
                      </v-btn>
                      <v-btn class="ml-5" color="teal" small icon @click="editReference(item.id)">
                        <v-icon size="20">far fa-edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>

        <div v-if="typeView === 2">
          <EditReference @returnList="returnList" :idEdit="idEdit" />
        </div>

        <div v-if="typeView === 1">
          <v-row class="border mt-n4" align="baseline" dense>
            <v-col cols="auto" class="py-0">
              <p class="pr-3 mb-0 borderRight" v-text="'Tipo de Referencia'" />
            </v-col>
            <v-col class="ml-3 mb-0 mt-0 py-0">
              <v-radio-group v-model="typeForm" row messages>
                <v-radio
                  color="primary"
                  label="Usuario Registrado"
                  on-icon="fas fa-check-circle"
                  :value="true"
                />
                <v-radio
                  color="primary"
                  label="Persona Externa"
                  on-icon="fas fa-check-circle"
                  :value="false"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <InnerReference v-if="typeForm" @returnList="returnList" @getAll="saveData" />
          <OutReference v-if="typeForm == false" @returnList="returnList" @getAll="saveData" />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import InnerReference from "./InnerReference";
import OutReference from "./OutReference";
import EditReference from "./EditReference";

export default {
  data() {
    return {
      typeView: 0,
      typeForm: null,
      referencesPersonal: [],
      referencesWork: [],
      idEdit: 0,
    };
  },
  components: {
    InnerReference,
    OutReference,
    EditReference,
  },
  created() {
    this.getAll();
  },
  methods: {
    editReference(id) {
      this.idEdit = id;
      this.typeView = 2;
    },
    getProfile(id) {
      if (id != 0) {
        this.$router
          .push({ name: "Profile", params: { id: id } })
          .catch((err) => {});
      }
    },
    returnList() {
      this.typeView = 0;
      this.typeForm = null;
      this.references = [];
      this.getAll();
    },
    saveData() {
      this.getAll();
      this.$emit("getData");
    },
    getAll() {
      axios.get(`api/reference`).then((response) => {
        this.referencesPersonal = response.data.filter(
          (item) => item.type != 2
        );
        this.referencesWork = response.data.filter((item) => item.type != 1);
      });
    },
    deleteReference(id) {
      axios.delete(`api/reference/${id}`).then((response) => {
        this.$swal(
          "Operación Exitosa",
          "Referencia eliminada con exito.",
          "success"
        );
        this.getAll();
        this.$emit("getData");
      });
    },
  },
};
</script>

