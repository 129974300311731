import "./bootstrap";
import "babel-polyfill";
import Vue from "vue";
import Vuetify from "vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as VueGoogleMaps from "vue2-google-maps";
import Routes from "./router.js";
import store from "./store/store";
import App from "./App";
import Cookies from "vue-js-cookie";

Vue.use(Vuetify);
Vue.use(Cookies);
Vue.use(VueSweetalert2, {
    confirmButtonColor: "#00A4CB",
    cancelButtonColor: "#C0392B"
});

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBoiHvn0oKShawHisn7ooYD42TdLgEZaMw",
        libraries: "places"
    },
    installComponents: true
});

export default new Vue({
    el: "#app",
    vuetify: new Vuetify({
        theme: {
            dark: false,
            themes: {
                light: {
                    primary: "#6F6F6F",
                    warning: "#F5C920",
                    error: "#C0392B",
                    inputBg: "#F6F6F6",
                    teal: "#00A4CB",
                    facebook: "#3B5999",
                    google: "#DB4A39",
                    twitter: "#1DA1F2",
                    grayAlt: "#E0E0E0",
                    backgroundApp: "#F9F9F9",
                    appBar: "#FFFFFF",
                    titleChat: "#EDEDED",
                    backgroundChat: "#d6e3e5",
                    sendBurbleChat: "#dcf8c6"
                },
                dark: {
                    primary: "#fff",
                    warning: "#F5C920",
                    error: "#C0392B",
                    inputBg: "#181818",
                    teal: "#00A4CB",
                    facebook: "#3B5999",
                    google: "#DB4A39",
                    twitter: "#1DA1F2",
                    grayAlt: "#202020",
                    backgroundApp: "#181818",
                    appBar: "#202020",
                    titleChat: "#202020",
                    backgroundChat: "#d6e3e5",
                    sendBurbleChat: "#dcf8c6"
                }
            }
        },
        icons: {
            iconfont: "fa"
        }
    }),
    router: Routes,
    store,
    render: h => h(App)
});
