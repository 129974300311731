<template>
  <v-row justify="center">
    <v-col cols="11" sm="9">
      <v-row no-gutters>
        <v-col cols="12">
          <p
            :class="titleSize"
            class="mb-1 text-justify"
            v-text="`Hola ${name.replace(' no aplica', '')}, ${loadingTrabies ? 'hemos encontrado estos' : 'estamos buscando'} trabajos cerca de ti.`"
          />
        </v-col>
      </v-row>
      <v-divider />
      <v-row v-if="!loadingTrabies">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in 4" :key="index">
          <v-card outlined elevation="1">
            <v-skeleton-loader type="list-item-avatar" />
            <v-divider />

            <v-card-text class="px-2 py-1">
              <v-row no-gutters>
                <v-col>
                  <v-skeleton-loader type="paragraph" />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-skeleton-loader type="text" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-2 py-2">
              <v-row justify="space-between" no-gutters>
                <v-col>
                  <v-skeleton-loader type="text" />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="loadingTrabies">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in trabers" :key="index">
          <v-card outlined elevation="1">
            <v-list-item @click="goProfile(item.id)" class="px-2">
              <v-list-item-avatar class="mr-2 my-1">
                <v-img
                  :src="item.image ? `storage/avatar/${item.id}/${item.image}` : 'images/incognita.jpg'"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${item.name} ${item.last_name.replace('no aplica', '')}`"
                  class="text-subtitle-2 font-weight-regular"
                />
              </v-list-item-content>
            </v-list-item>

            <v-divider />
            <v-card-text class="px-3 py-2">
              <v-row no-gutters>
                <v-col>
                  <p class="mb-0 d-flex">
                    <v-icon color="teal" left size="14">fas fa-map-marker-alt</v-icon>
                    {{item.parroquia ? item.parroquia.canton.canton : 'CUENCA'}}, {{item.parroquia ? item.parroquia.canton.provincia.provincia : 'AZUAY'}}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mb-2" />

            <v-card-actions class="px-3 pt-0 pb-2">
              <v-row justify="space-between" no-gutters>
                <v-col>
                  <v-btn color="primary" text small class="pl-0">
                    <v-icon color="teal" size="20" class="pr-2">far fa-comment-alt</v-icon>Contactar
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col class="d-flex justify-end">
                  <v-btn color="primary" icon small @click="route(item.id)">
                    <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                  </v-btn>

                  <v-btn color="primary" icon small>
                    <v-icon color="teal" size="20">fa fa-share-alt</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="loadingTrabies">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in trabies" :key="index">
          <v-card outlined elevation="1">
            <v-list-item @click="goProfile(item.give)" class="px-2">
              <v-list-item-avatar class="mr-2 my-1">
                <v-img
                  :src="item.user.image ? `storage/avatar/${item.user.id}/${item.user.image}` : `images/incognita.jpg`"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${item.user.name} ${item.user.last_name.replace('no aplica', '')}`"
                  class="text-subtitle-2 font-weight-regular"
                />
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-card-text class="px-2 py-1">
              <v-row no-gutters>
                <v-col>
                  <p
                    class="text-truncate mb-0 text-capitalize text-uppercase"
                  >{{trabies ? item.title : ''}} {{item.intern ? '(Practicante)' : ''}}</p>
                </v-col>
              </v-row>
              <v-row no-gutters class="border" align="baseline">
                <v-col cols="3">
                  <p
                    class="mb-1 text-subtitle-2"
                  >{{item.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</p>
                </v-col>
                <v-col cols="9">
                  <p
                    class="text-caption mb-1 text-right"
                  >{{item.trabiesable ? item.trabiesable.description : ''}}</p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pa-0">
                  <v-row no-gutters>
                    <v-col>
                      <p
                        class="text-caption mb-0 text-truncate text-capitalize"
                        v-text="`Publicado: ${since(item.created_at)}`"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-2 py-1">
              <v-row justify="space-between" no-gutters>
                <v-col>
                  <v-btn color="primary" text small @click="contactTraby(item.id)" class="pl-0">
                    <v-icon color="teal" size="20" class="pr-2">far fa-comment-alt</v-icon>Contactar
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col class="d-flex justify-end">
                  <v-btn color="primary" icon small @click="showInfoTraby(item.id)">
                    <v-icon color="teal" size="20">far fa-plus-square</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <p :class="titleSize" class="mb-1 text-justify" v-text="`Actividad`" />
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col cols="12">
          <v-alert class="white--text ma-0" color="teal" :value="true" outlined>
            <div class="d-flex justify-center aling-center">
              <v-icon color="teal" left size="30">far fa-edit</v-icon>
              <span class="text-h6">Completa tu perfil para obtener mejores resultados</span>
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12">
          <p v-if="trabyAssignedCount === 0" class="mb-0 text-center text-subtitle-1">
            <v-icon color="teal" size="30" left>far fa-calendar-minus</v-icon>No tienes trabys pendientes para el dia de hoy.
          </p>
          <p v-else class="mb-0 text-center text-subtitle-1">
            <v-icon color="teal" @click="route('Calendar')" size="30" left>far fa-calendar-alt</v-icon>
            {{`Tienes ${trabyAssignedCount} traby${trabyAssignedCount > 1 ? 's' : ''} pendiente${trabyAssignedCount > 1 ? 's' : ''}, revisa tu calendario.`}}
          </p>
        </v-col>
      </v-row>-->
      <v-divider />
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-tooltip top color="teal">
            <template v-slot:activator="{ on }">
              <v-btn outlined small block color="primary" @click="route('GiveTraby')" v-on="on">
                <v-icon color="teal" left small>far fa-newspaper</v-icon>Publicar Traby
              </v-btn>
            </template>
            <span>Publicar Trabajo</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-tooltip top color="teal">
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                ele
                small
                block
                color="primary"
                v-on="on"
                @click="route('SearchTraby')"
              >
                <v-icon color="teal" left small>fas fa-search-dollar</v-icon>BUSCAR TRABY
              </v-btn>
            </template>
            <span>Buscar Trabajo</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-tooltip top color="teal">
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                ele
                small
                block
                color="primary"
                v-on="on"
                @click="route('SearchTraber')"
              >
                <v-icon color="teal" left small>fas fa-search</v-icon>BUSCAR TRABER
              </v-btn>
            </template>
            <span>Buscar Trabajador</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-btn outlined ele small block color="primary" @click="route('Account')">
            <v-icon color="teal" left small>far fa-user</v-icon>CONFIGURAR PERFIL
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-carousel
            height="300"
            cycle
            hide-delimiters
            show-arrows-on-hover
            next-icon="fa fa-angle-right"
            prev-icon="fa fa-angle-left"
          >
            <v-carousel-item src="../../../images/banner2.png">
              <v-container fill-height>
                <v-row no-gutters>
                  <v-col class="d-flex flex-column justify-center align-end pr-12 mr-12">
                    <p class="white--text text-h4 textShadowBanner" v-text="`Ayudanos a ser`" />
                    <p
                      class="white--text text-h4 textShadowBanner"
                      v-text="`una mejor plataforma para ti.`"
                    />
                    <v-btn
                      color="warning"
                      depressed
                      elevation="1"
                      large
                      @click="poll.dialogPoll = true"
                    >Encuesta</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
            <v-carousel-item src="../../../images/banner1.png">
              <v-container fill-height>
                <v-row no-gutters>
                  <v-col class="d-flex flex-column justify-center align-end pr-12 mr-12">
                    <p
                      class="white--text text-h4 textShadowBanner"
                      v-text="`Tu opinion nos importa`"
                    />
                    <p
                      class="white--text text-h4 textShadowBanner"
                      v-text="`por eso ayudanos a ser mejores.`"
                    />
                    <v-btn
                      color="warning"
                      depressed
                      elevation="1"
                      large
                      @click="poll.dialogPoll = true"
                    >Encuesta</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="poll.dialogPoll"
      scrollable
      overlay
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title class="d-flex justify-center primary white--text">ENCUESTA</v-card-title>
        <v-form ref="pollForm">
          <v-container grid-list-xs>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="poll.changeInApp"
                  label="Que cambiarias en la plataforma"
                  color="primary"
                  background-color="inputBg"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="poll.usefulApp"
                  label="Te parece útil la app? Porque? Para que?"
                  color="primary"
                  background-color="inputBg"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="poll.functionLike"
                  label="Describe lo que mas te gusta de la App"
                  color="primary"
                  background-color="inputBg"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="poll.otherCategory"
                  label="Necesitas otra categoria para tu habilidad? Cual?"
                  color="primary"
                  background-color="inputBg"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="poll.comments"
                  label="Comentario adicional"
                  color="primary"
                  background-color="inputBg"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="text-center">Califica del 1 al 5 la facilidad de uso de la App</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-center">
                <v-radio-group v-model="poll.ux" row hide-details class="mt-n3">
                  <v-radio color="primary" label="1" on-icon="fas fa-check-circle" value="1" />
                  <v-radio color="primary" label="2" on-icon="fas fa-check-circle" value="2" />
                  <v-radio color="primary" label="3" on-icon="fas fa-check-circle" value="3" />
                  <v-radio color="primary" label="4" on-icon="fas fa-check-circle" value="4" />
                  <v-radio color="primary" label="5" on-icon="fas fa-check-circle" value="5" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-5">
              <v-col cols="12">
                <p class="text-center">Califica del 1 al 5 el diseño de la App</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-center">
                <v-radio-group v-model="poll.design" row hide-details class="mt-n3">
                  <v-radio color="primary" label="1" on-icon="fas fa-check-circle" value="1" />
                  <v-radio color="primary" label="2" on-icon="fas fa-check-circle" value="2" />
                  <v-radio color="primary" label="3" on-icon="fas fa-check-circle" value="3" />
                  <v-radio color="primary" label="4" on-icon="fas fa-check-circle" value="4" />
                  <v-radio color="primary" label="5" on-icon="fas fa-check-circle" value="5" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" depressed elevation="1" small @click="savePoll">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTypeFunction" max-width="400px" persistent>
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text title ma-0">Bienvenido a Traby</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="py-2">
          <v-row no-gutters>
            <v-col>
              <p
                class="text-justify text-body-2 mb-n2"
              >Para disfrutar todo lo que Traby tiene, cuéntanos cuál es tu interes?.</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-1">
            <v-col>
              <v-radio-group v-model="typeActionUser" hide-details>
                <v-radio
                  label="Publicar ofertas laborales"
                  on-icon="fas fa-check-circle"
                  value="1"
                />
                <v-radio label="Buscar ofertas laborales" on-icon="fas fa-check-circle" value="2" />
                <v-radio label="Ambas opciones" on-icon="fas fa-check-circle" value="3" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2">
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <v-btn small outlined color="primary" @click="updateType">Guardar Cambios</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTraby" max-width="400px">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <p class="teal--text title ma-0">Detalles del Traby</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <GmapMap
                ref="mapRef"
                :center="{ lat: Number(trabyInfo.latitude_init), lng: Number(trabyInfo.longitude_init) }"
                :clickable="true"
                :zoom="16"
                :options="mapOptions"
                class="heightMap150"
              >
                <GmapMarker
                  icon="images/maker.png"
                  :clickable="true"
                  :draggable="true"
                  :position="{ lat: Number(trabyInfo.latitude_init), lng: Number(trabyInfo.longitude_init) }"
                />
              </GmapMap>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-subtitle-2 ma-0">Descripcion:</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border">
            <v-col>
              <p class="text-justify text-body-2 mb-1">{{trabyInfo.description}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p
                class="mb-1 text-subtitle-2"
              >{{trabyInfo.trabiesable_type == 'App\\Category' ? 'Categoria' : 'Profesion'}}:</p>
            </v-col>
            <v-col cols="6">
              <p class="text-body-2 mb-1 text-right">{{trabyInfo.trabiesable.description}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline" justify="space-between">
            <v-col cols="auto">
              <p class="mb-1 text-subtitle-2">Fecha y Hora:</p>
            </v-col>
            <v-col cols="auto">
              <p class="text-body-2 mb-1 text-right">{{dayTraby(trabyInfo.time_need)}}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="border" align="baseline">
            <v-col cols="6">
              <p class="mb-1 text-subtitle-2">Oferta:</p>
            </v-col>
            <v-col cols="6">
              <p class="text-body-2 mb-1 text-right">$ {{trabyInfo.price_init}}</p>
            </v-col>
          </v-row>
          <div v-if="trabyInfo.photos_traby.length > 0">
            <v-row no-gutters class>
              <v-col cols="12">
                <p class="text-subtitle-2 my-2 text-center">Referencia Visual</p>
              </v-col>
            </v-row>
            <v-row no-gutters justify="space-between">
              <v-col
                cols="3"
                class="d-flex justify-center mx-1 mb-3"
                v-for="(image,i) in trabyInfo.photos_traby"
                :key="i"
              >
                <v-img
                  height="50px"
                  width="50px"
                  :src="`storage/trabies/${trabyInfo.id}/${image.image}`"
                />
              </v-col>
            </v-row>
          </div>
          <v-row no-gutters>
            <v-col>
              <v-btn
                outlined
                small
                block
                color="primary"
                @click="dialogTraby = false"
              >Regresar a busqueda</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTrabyMessage" persistent max-width="400px">
      <v-card>
        <v-card-title class="ma-0 pa-0 py-1 elevationTeal1">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <v-avatar size="36px">
                <v-img
                  :src="traby.user ? `storage/avatar/${traby.user.id}/${traby.user.image}` :''"
                />
              </v-avatar>
            </v-col>
            <v-col cols="auto">
              <p
                class="ml-3 teal--text title ma-0"
              >{{traby.user ? traby.user.name : ''}} {{traby.user ? traby.user.last_name.replace('no aplica', '') : ''}}</p>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="text-subtitle-2 ma-0">Descripcion:</p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <p class="text-justify text-body-2 mb-0">{{traby.description}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                hide-details
                label="Mensaje"
                color="primary"
                background-color="inputBg"
                outlined
                no-resize
                v-model="message"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="counterOffer"
                hide-details
                label="Oferta"
                number
                color="primary"
                background-color="inputBg"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mt-3" no-gutters>
            <v-col cols="5">
              <v-btn
                outlined
                small
                block
                color="primary"
                @click="dialogTrabyMessage = false"
              >Regresar</v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn outlined small block color="primary" @click="sendMessage">Contactar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      typeActionUser: null,
      typeActionUserItems: [
        {
          id: "1",
          description: "Publicar ofertas laborales",
        },
        {
          id: "2",
          description: "Buscar ofertas laborales",
        },
        {
          id: "3",
          description: "Ambas opciones",
        },
      ],
      dialogTypeFunction: false,
      loadingTrabies: false,
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      poll: {
        dialogPoll: false,
        changeInApp: null,
        usefulApp: null,
        functionLike: null,
        otherCategory: null,
        comments: null,
        design: null,
        ux: null,
      },
      trabies: [],
      trabers: [],
      trabyInfo: {
        description: null,
        time_need: null,
        latitude_init: null,
        longitude_init: null,
        trabiesable: {
          description: null,
        },
        price_traby: {
          price: null,
        },
        photos_traby: [],
      },
      dialogTraby: false,
      trabyAssignedCount: 0,
      traby: {
        description: null,
        time_need: null,
        latitude_init: null,
        longitude_init: null,
        category: {
          description: null,
        },
        price_traby: {
          price: null,
        },
        photos_traby: [],
      },
      counterOffer: null,
      dialogTrabyMessage: false,
      message: null,
      id: null,
      name: "",
      type: null,
    };
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    getPosition() {
      return new Promise((res, rej) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(res, rej);
        }
      });
    },
    async getDataByTpe(type, latitude, longitude) {
      let minLat = latitude - 0.02;
      let maxLat = latitude + 0.02;
      let minLng = longitude - 0.02 / Math.cos((latitude * Math.PI) / 180);
      let maxLng = longitude + 0.02 / Math.cos((latitude * Math.PI) / 180);

      if (type === 1) {
        const trabers = await axios.get(
          `api/user/1/filter/null/null/${minLat}/${maxLat}/${minLng}/${maxLng}`
        );
        this.trabers = trabers.data.data;
        this.loadingTrabies = true;
      } else {
        const trabies = await axios.get(
          `api/traby/1/filter/null/null/null/null/${minLat}/${maxLat}/${minLng}/${maxLng}`
        );

        const countTrabyUser = axios.get(`api/trabyByUser/${this.id}`);

        this.trabyAssignedCount = countTrabyUser.data;
        this.trabies = trabies.data.data;
        this.loadingTrabies = true;
      }
    },
    async updateType() {
      try {
        if (!this.typeActionUser) {
          this.$swal(
            "Error",
            "El campo solicitado es obligatorio, para que la plataforma se adapte a ti",
            "error"
          );
          return;
        }

        await axios.put("api/userType", {
          type: this.typeActionUser,
        });
        this.$swal(
          "Operación Exitosa",
          "Felicidades, Hemos actualizado tu configuracion.",
          "success"
        );
        this.dialogTypeFunction = false;
        let latitude, longitude;
        const { coords: position } = await this.getPosition();
        ({ latitude, longitude } = position);

        this.getDataByTpe(Number(this.typeActionUser), latitude, longitude);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAll() {
      try {
        const dataUser = await axios.get(`api/user/data`);
        this.id = dataUser.data.id;
        this.name = dataUser.data.name;
        this.type = dataUser.data.type;

        if (!this.type) {
          this.dialogTypeFunction = true;
          return;
        }

        let latitude, longitude;
        const { coords: position } = await this.getPosition();
        ({ latitude, longitude } = position);

        this.getDataByTpe(Number(this.type), latitude, longitude);
      } catch (error) {
        console.error(error);
      }
    },
    showInfoTraby(id) {
      axios.get(`api/traby/${id}`).then((response) => {
        this.trabyInfo = response.data;
        this.dialogTraby = true;
      });
    },
    contactTraby(id) {
      axios.get(`api/user/data`).then((response) => {
        if (response.data.state !== 0) {
          axios.get(`api/traby/${id}`).then((response) => {
            this.traby = response.data;
            this.counterOffer = this.traby.price_init;
            this.dialogTrabyMessage = true;
          });
        } else {
          this.$swal({
            title: "Completa tu perfil",
            text: "Antes de continuar necesitamos que completes tu perfil",
            confirmButtonColor: "#00A4CB",
            confirmButtonText: "Completar",
          }).then(({ value }) => {
            if (value) {
              this.$router.push({ name: "Account" }).catch((err) => {});
            }
          });
        }
      });
    },
    sendMessage() {
      axios
        .post(`api/message`, {
          traby_id: this.traby.id,
          give: this.traby.user.id,
          description: this.message,
          price: this.counterOffer,
        })
        .then((response) => {
          this.$swal(
            "Operación Exitosa",
            "Felicidades, Hemos enviado tu mensaje.",
            "success"
          );
          this.dialogTrabyMessage = false;
          this.traby = {
            description: null,
            time_need: null,
            latitude_init: null,
            longitude_init: null,
            category: {
              description: null,
            },
            photos_traby: [],
          };
        });
    },
    route(ruta) {
      this.$router.push({ name: ruta }).catch((err) => {});
    },
    dayTraby(time) {
      return `${moment(time).format("lll")}`;
    },
    since(time) {
      return moment(time).fromNow();
    },
    changeDate(date) {
      var timeString = date.substring(11, 19);
      var date = date.substring(0, 11);
      var H = +timeString.substr(0, 2);
      var h = H % 12 || 12;
      var ampm = H < 12 ? "AM" : "PM";
      timeString = date + h + timeString.substr(2, 3) + ampm;
      return timeString;
    },
    goProfile(profileId) {
      this.$router
        .push({ name: "Profile", params: { id: profileId } })
        .catch((err) => {});
    },
    savePoll() {
      axios
        .post("api/poll", {
          changeInApp: this.poll.changeInApp,
          usefulApp: this.poll.usefulApp,
          functionLike: this.poll.functionLike,
          otherCategory: this.poll.otherCategory,
          comments: this.poll.comments,
          design: this.poll.design,
          ux: this.poll.ux,
        })
        .then((response) => {
          this.$swal(
            "Correcto",
            "Gracias por llenar la informacion, pronto nos pondremos en contacto con tigo.",
            "success"
          );
          this.poll.dialogPoll = false;
          this.$refs.pollForm.reset();
        });
    },
  },
  computed: {
    titleSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-subtitle-2 font-weight-bold";
        case "sm":
          return "text-subtitle-1 font-weight-bold";
        default:
          return "text-h6";
      }
    },
  },
};
</script>