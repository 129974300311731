<template>
  <v-container grid-list-xs fluid fill-height class="back">
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-card max-width="350px" min-width="300px" class="ma-0">
          <v-row no-gutters class="pt-4">
            <v-col cols="12">
              <v-img src="images/logo.svg" aspect-ratio="3" contain height="80px" />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <p class="ma-0 text-caption">Version Beta</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4 pt-2">
            <v-col cols="12">
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    small
                    elevation="1"
                    depressed
                    color="facebook"
                    block
                    @click="socialLogin(2)"
                    dark
                  >
                    <v-icon size="16" color="white" class="mr-2">fab fa-facebook-f</v-icon>Ingresa con Facebook
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    small
                    elevation="1"
                    depressed
                    color="grayAlt"
                    block
                    @click="socialLogin(1)"
                  >
                    <svg width="25" height="25" class="mr-2">
                      <g>
                        <path
                          d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z"
                          fill="#4285F4"
                        />
                        <path
                          d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z"
                          fill="#34A853"
                        />
                        <path
                          d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z"
                          fill="#db4a39"
                        />
                      </g>
                    </svg>
                    Ingresa con Google
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-center">
              <p class="my-3 lineasLados text-subtitle-2">O</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4 pb-4">
            <v-col cols="12">
              <FormLogin ref="FormLogin" v-if="view == 'formLogin'" @changeView="view = $event" />
              <FormSignIn ref="FormSignIn" v-if="view == 'formSignIn'" @changeView="view = $event" />
            </v-col>
          </v-row>
          <v-card-actions class="px-4 py-3 grey lighten-4">
            <v-row no-gutters>
              <v-col cols="12">
                <p
                  class="mb-2 font-weight-bold text-body-2 text-center"
                >{{view == 'formLogin' ? '¿Aún no tienes cuenta en Traby?' : '¿Ya tienes cuenta?'}}</p>
                <v-btn
                  small
                  block
                  elevation="1"
                  dark
                  color="teal"
                  @click="changeView"
                >{{view == 'formLogin' ? 'Registrate' : 'Inicia sesión'}}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-btn
          class="mt-4 font-weight-bold text-capitalize"
          block
          text
          color="white"
          @click="company"
        >Registra tu Cuenta Empresarial</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormLogin from "./FormLogin";
import FormSignIn from "./FormSignIn";
export default {
  data() {
    return {
      view: "formLogin",
    };
  },
  components: {
    FormLogin,
    FormSignIn,
  },
  methods: {
    socialLogin(type) {
      axios.post("api/socialLogin", { red: type }).then((response) => {
        window.open(response.data, "_self");
      });
    },
    changeView() {
      this.view = this.view == "formSignIn" ? "formLogin" : "formSignIn";
    },
    company() {
      this.$router.push({ name: "registerCompany" }).catch((err) => {});
    },
  },
};
</script>

<style scoped>
.back {
  background: linear-gradient(to bottom, #00ccfa, #00a4cb, #007d99);
}
</style>

