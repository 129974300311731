<template>
  <v-row justify="center">
    <v-col cols="11" sm="10">
      <v-row class="fill-height">
        <v-col cols="12">
          <v-sheet height="auto" elevation="2">
            <v-toolbar flat color="white">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>fas fa-angle-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Día</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mes</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>fas fa-angle-left</v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>fas fa-angle-right</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title.toUpperCase() }}</v-toolbar-title>
            </v-toolbar>
          </v-sheet>
          <v-sheet :height="`${this.$vuetify.breakpoint.height-200}px`">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              event-color="teal"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            />
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card max-width="320px" flat>
                <v-toolbar color="teal" dark flat>
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-avatar size="35">
                        <v-img
                          class="cursosrPointer"
                          @click="route('Profile')"
                          :src="selectedEvent.traby.user.image ? `storage/avatar/${selectedEvent.traby.user.id}/${selectedEvent.traby.user.image}` : 'images/incognita.jpg' "
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="auto" class="ml-2">
                      <p
                        class="mb-0 text-body-1 font-weight-bold"
                      >{{`${selectedEvent.traby.user.name} ${selectedEvent.traby.user.last_name}`}}</p>
                    </v-col>
                  </v-row>
                </v-toolbar>
                <v-card-text class="px-2 py-1">
                  <p class="mb-0 text-body-1 font-weight-bold">{{selectedEvent.traby.title || '' }}</p>
                  <p class="mb-2 text-caption">{{selectedEvent.traby.description || '' }}</p>
                  <p class="mb-0 text-caption">
                    <b>Fecha y Hora:</b>
                    {{timeRequired(selectedEvent.traby.time_need) || '' }}
                  </p>
                  <p class="mb-0 text-caption">
                    <b>Precio:</b>
                    $ {{selectedEvent.traby.conversation_calendar.last_price.price || '' }}
                  </p>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
      },
      selectedEvent: {
        traby: {
          user: {
            image: null,
          },
          conversation_calendar: {
            last_price: {
              price: 0,
            },
          },
        },
      },
      selectedElement: null,
      selectedOpen: false,
      events: [],
    };
  },
  methods: {
    timeRequired(time) {
      return moment(time).format("lll");
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.events = [];
      axios.get("api/calendar").then((response) => {
        response.data.forEach((traby) => {
          this.events.push({
            name: traby.traby_calendar.title,
            start: traby.traby_calendar.time_need,
            end: traby.traby_calendar.time_need,
            traby: traby.traby_calendar,
            timed: false,
          });
        });
      });
    },
  },
};
</script>