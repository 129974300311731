export default {
    namespaced: false,
    state: {
        search: null,
        hidden: true
    },
    mutations: {
        SEARCH(state, data) {
            state.search = data.search;
            state.hidden = data.hidden;
        },
        SET_DEFAULT(state) {
            state.search = null;
            state.hidden = true;
        },
        SET_HIDDEN(state) {
            state.hidden = false;
        }
    },
    actions: {
        setSearch(context, data) {
            context.commit("SEARCH", {
                search: data.search,
                hidden: false
            });
        },
        setSearchDefautl(context) {
            context.commit("SET_DEFAULT");
        },
        setHidden(context) {
            context.commit("SET_HIDDEN");
        }
    }
};
