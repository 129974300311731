window._ = require("lodash");

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

window.axios.defaults.headers.common["Content-Type"] = "application/json";

window.axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("access_token") || null;

let token = document.head.querySelector('meta[name="csrf-token"]');

window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.MIX_PUSHER_APP_KEY,
    wsHost: window.location.hostname,
    wsPort: 6001,
    disableStats: true,
    auth: {
        headers: {
            Authorization:
                "Bearer " + localStorage.getItem("access_token") || null
        }
    }
});
