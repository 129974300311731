<template>
    <v-container grid-list-xs fluid>
        <v-row justify="center" no-gutters class="mt-3">
            <v-col cols="12" sm="10">
                <v-row no-gutters justify="space-around">
                    <v-col cols="12" md="3">
                        <v-card flat outlined elevation="1">
                            <v-row justify="center" dense class="pt-2 px-1">
                                <v-col cols="auto">
                                    <v-avatar color="primary" size="160">
                                        <v-img :src="imageProfile" />
                                    </v-avatar>
                                </v-col>
                            </v-row>
                            <v-row justify="center" no-gutters class="px-1">
                                <v-col cols="12">
                                    <p
                                        class="text-center mb-0 text-h6 text-uppercase primary--text font-weight-bold"
                                    >
                                        {{ user.last_name }} {{ user.name }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row
                                justify="center"
                                dense
                                align="center"
                                class="px-1"
                            >
                                <v-col cols="auto">
                                    <p class="text-center mb-0 text-body-2">
                                        Puntuacion: {{ qualificationStars }}
                                    </p>
                                </v-col>
                                <v-col cols="auto">
                                    <p
                                        class="text-center mb-0 text-body-2 mt-n1"
                                    >
                                        <v-icon small color="warning"
                                            >fas fa-star</v-icon
                                        >
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row
                                justify="center"
                                no-gutters
                                align="center"
                                class="px-1"
                            >
                                <v-col cols="auto">
                                    <p
                                        class="text-center mb-0 text-body-2 teal--text"
                                    >
                                        {{
                                            user.parroquia_id
                                                ? `${user.parroquia.canton.canton}, ${user.parroquia.canton.provincia.provincia},`
                                                : ""
                                        }}
                                        Ecuador
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row
                                justify="center"
                                dense
                                align="center"
                                class="pb-2 px-1"
                            >
                                <v-col cols="auto">
                                    <p class="text-center mb-0 text-caption">
                                        <b>Miembro desde:</b>
                                        {{ since(user.created_at) }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider />
                            <v-card-text class="px-3 py-1">
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <p
                                            :class="
                                                user.state ? '' : 'text-center'
                                            "
                                            class="mb-0 text-subtitle-1 font-weight-bold text-center"
                                        >
                                            {{
                                                user.state
                                                    ? "Perfil no Verificado"
                                                    : "Completa tu perfil, para puedas tener una informacion mas completa"
                                            }}
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <p class="mb-0 primary--color">
                                            <v-icon size="14" color="teal" left
                                                >far fa-check-circle</v-icon
                                            >Correo verificado
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <p class="mb-0 primary--color">
                                            <v-icon size="14" color="teal" left
                                                >far fa-check-circle</v-icon
                                            >
                                            Teléfono: {{ user.phone }}
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <p
                                            class="mb-0 text-subtitle-1 font-weight-bold text-center text-center"
                                        >
                                            Contactar
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pb-0 mt-n4">
                                        <v-btn
                                            flat
                                            icon
                                            color="primary"
                                            @click="sendWhatsapp"
                                        >
                                            <v-icon color="teal"
                                                >fab fa-whatsapp</v-icon
                                            >
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <p class="mb-0 primary--color">
                                            <v-icon
                                                size="14"
                                                color="primary"
                                                left
                                                >far fa-times-circle</v-icon
                                            >Documento de identidad no
                                            verificado
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <p class="mb-0 primary--color">
                                            <v-icon
                                                size="14"
                                                color="primary"
                                                left
                                                >far fa-times-circle</v-icon
                                            >Direccion no verificada
                                        </p>
                                    </v-col>
                                </v-row>

                                <v-row justify="center">
                                    <v-col cols="auto">
                                        <v-btn
                                            color="warning"
                                            depressed
                                            elevation="1"
                                        >
                                            Verificate
                                            <v-icon
                                                color="teal"
                                                class="ml-2"
                                                size="20"
                                                >fas fa-certificate</v-icon
                                            >
                                            <v-icon
                                                class="checkVerificationMargin"
                                                color="white"
                                                size="10"
                                                >fas fa-check</v-icon
                                            >
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters justify="center">
                                    <v-col cols="10" class="pb-0">
                                        <p
                                            class="mb-0 primary--color text-caption text-center"
                                        >
                                            Los usuarios verificados generan aún
                                            mas confianza, y encuentran trabajo
                                            mas rápido.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        md="9"
                        :class="
                            $vuetify.breakpoint.width < 960 ? 'mt-5' : 'px-3'
                        "
                    >
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card flat outlined elevation="1">
                                    <v-card-text class="px-0 py-2">
                                        <v-row dense justify="space-around">
                                            <v-col cols="auto">
                                                <v-btn
                                                    :color="
                                                        typeView === 1
                                                            ? 'teal'
                                                            : 'primary'
                                                    "
                                                    text
                                                    small
                                                    @click="typeView = 1"
                                                    >Estudios</v-btn
                                                >
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    :color="
                                                        typeView === 2
                                                            ? 'teal'
                                                            : 'primary'
                                                    "
                                                    text
                                                    small
                                                    @click="typeView = 2"
                                                    >Habilidades</v-btn
                                                >
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    :color="
                                                        typeView === 3
                                                            ? 'teal'
                                                            : 'primary'
                                                    "
                                                    text
                                                    small
                                                    @click="typeView = 3"
                                                    >Referencias</v-btn
                                                >
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    :color="
                                                        typeView === 4
                                                            ? 'teal'
                                                            : 'primary'
                                                    "
                                                    text
                                                    small
                                                    @click="typeView = 4"
                                                    >Calificaciones</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="mt-2">
                            <v-col cols="12">
                                <v-card
                                    flat
                                    outlined
                                    elevation="1"
                                    v-if="typeView === 1"
                                >
                                    <v-card-text class="px-4 py-2">
                                        <v-row dense>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    <v-icon size="14"
                                                        >far
                                                        fa-check-circle</v-icon
                                                    >
                                                    <b>Estudios Realizados:</b>
                                                </p>
                                            </v-col>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    {{
                                                        user.study
                                                            ? level
                                                            : "No ingresado"
                                                    }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <template v-if="aditionalStudies">
                                            <v-row dense>
                                                <v-col cols="auto">
                                                    <p class="mb-0">
                                                        <v-icon size="14"
                                                            >far
                                                            fa-dot-circle</v-icon
                                                        >
                                                        <b
                                                            >Estudios
                                                            Adicionales:</b
                                                        >
                                                    </p>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                class="ml-3"
                                                no-gutters
                                                v-for="(item,
                                                index) in user.further_studies"
                                                :key="index"
                                            >
                                                <v-col cols="auto">
                                                    <p class="mb-0">
                                                        <v-icon size="14"
                                                            >far
                                                            fa-check-circle</v-icon
                                                        >
                                                        {{ item.description }} (
                                                        {{
                                                            item
                                                                .further_studies_type
                                                                .description
                                                        }}
                                                        )
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template
                                            v-if="user.courses.length > 0"
                                        >
                                            <v-row dense>
                                                <v-col cols="auto">
                                                    <p class="mb-0">
                                                        <v-icon size="14"
                                                            >far
                                                            fa-dot-circle</v-icon
                                                        >
                                                        <b
                                                            >Cursos
                                                            Realizados:</b
                                                        >
                                                    </p>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                class="ml-3"
                                                no-gutters
                                                v-for="item in user.courses"
                                                :key="item.id + 100"
                                            >
                                                <v-col cols="auto">
                                                    <p class="mb-0">
                                                        <v-icon size="14"
                                                            >far
                                                            fa-check-circle</v-icon
                                                        >
                                                        {{ item.description }},
                                                        Duracion:
                                                        {{ item.hours }} horas
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-card-text>
                                </v-card>
                                <v-card
                                    flat
                                    outlined
                                    elevation="1"
                                    v-if="typeView === 2"
                                >
                                    <v-card-text class="px-4 py-2">
                                        <v-row dense>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    <v-icon size="14"
                                                        >far
                                                        fa-check-circle</v-icon
                                                    >
                                                    <b
                                                        >Habilidades Del
                                                        Trabajador:</b
                                                    >
                                                </p>
                                            </v-col>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    {{
                                                        user.skill_user
                                                            .length === 0
                                                            ? "No ingresadas"
                                                            : ""
                                                    }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <template
                                            v-if="user.skill_user.length > 0"
                                        >
                                            <v-row
                                                class="ml-3"
                                                no-gutters
                                                v-for="(item,
                                                index) in user.skill_user"
                                                :key="index"
                                            >
                                                <v-col cols="auto">
                                                    <p class="mb-0">
                                                        <v-icon size="14"
                                                            >far
                                                            fa-check-circle</v-icon
                                                        >
                                                        {{ item.skill }} -
                                                        {{ item.additional }} (
                                                        <b>{{
                                                            item.category
                                                                .description
                                                        }}</b>
                                                        )
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-card-text>
                                </v-card>
                                <v-card
                                    flat
                                    outlined
                                    elevation="1"
                                    v-if="typeView === 3"
                                >
                                    <v-card-text class="px-4 py-2">
                                        <template
                                            v-if="referencesPersonal.length > 0"
                                        >
                                            <v-row dense>
                                                <v-col cols="auto">
                                                    <p class="mb-0">
                                                        <v-icon size="14"
                                                            >far
                                                            fa-dot-circle</v-icon
                                                        >
                                                        <b
                                                            >Referencias
                                                            Personales:</b
                                                        >
                                                    </p>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                class="ml-3"
                                                v-for="(item,
                                                index) in referencesPersonal"
                                                :key="index"
                                                align="center"
                                            >
                                                <v-col cols="auto">
                                                    <v-avatar
                                                        size="50"
                                                        :style="
                                                            item.user_reference
                                                                ? 'cursor:pointer'
                                                                : ''
                                                        "
                                                        @click="
                                                            goProfile(
                                                                item.user_reference
                                                                    ? item.user_reference
                                                                    : 0
                                                            )
                                                        "
                                                    >
                                                        <v-img
                                                            :src="
                                                                item.user_reference
                                                                    ? `storage/avatar/${item.reference.id}/${item.reference.image}`
                                                                    : 'images/incognita.jpg'
                                                            "
                                                        />
                                                    </v-avatar>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-row
                                                        no-gutters
                                                        align="center"
                                                    >
                                                        <p
                                                            class="mb-0 text-body-1"
                                                        >
                                                            {{
                                                                item.user_reference
                                                                    ? `${item.reference.name} ${item.reference.last_name}`
                                                                    : `${item.name} ${item.last_name}`
                                                            }}
                                                        </p>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template
                                            v-if="referencesWork.length > 0"
                                        >
                                            <v-row dense>
                                                <v-col cols="auto">
                                                    <p class="mb-0">
                                                        <v-icon size="14"
                                                            >far
                                                            fa-dot-circle</v-icon
                                                        >
                                                        <b
                                                            >Referencias
                                                            Laborales:</b
                                                        >
                                                    </p>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                class="ml-3"
                                                v-for="(item,
                                                index) in referencesWork"
                                                :key="index + 5"
                                                align="center"
                                            >
                                                <v-col cols="auto">
                                                    <v-avatar size="50">
                                                        <v-img
                                                            :src="
                                                                item.user_reference
                                                                    ? `storage/avatar/${item.reference.id}/${item.reference.image}`
                                                                    : 'images/incognita.jpg'
                                                            "
                                                        />
                                                    </v-avatar>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-row
                                                        no-gutters
                                                        align="center"
                                                    >
                                                        <p
                                                            class="mb-0 text-body-1"
                                                        >
                                                            {{
                                                                item.user_reference
                                                                    ? `${item.reference.name} ${item.reference.last_name}`
                                                                    : `${item.name} ${item.last_name}`
                                                            }}
                                                        </p>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-card-text>
                                </v-card>
                                <v-card
                                    flat
                                    outlined
                                    elevation="1"
                                    v-if="typeView === 4"
                                >
                                    <v-card-text class="px-4 py-2">
                                        <v-row dense>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    <v-icon size="14"
                                                        >far
                                                        fa-check-circle</v-icon
                                                    >
                                                    <b
                                                        >Calificaciones como
                                                        trabajador:</b
                                                    >
                                                </p>
                                            </v-col>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    {{
                                                        user
                                                            .qualifications_recive
                                                            .length === 0
                                                            ? "No ha realizado Trabajos al dia."
                                                            : ""
                                                    }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <template
                                            v-if="
                                                user.qualifications_recive
                                                    .length > 0
                                            "
                                        >
                                            <v-row
                                                v-for="(item,
                                                index) in user.qualifications_recive"
                                                :key="index"
                                            >
                                                <v-col cols="auto">
                                                    <v-row no-gutters>
                                                        <v-col cols="12">
                                                            <v-avatar
                                                                size="70"
                                                                style="cursor:pointer"
                                                                @click="
                                                                    goProfile(
                                                                        item
                                                                            .give
                                                                            .id
                                                                    )
                                                                "
                                                            >
                                                                <v-img
                                                                    :src="
                                                                        item.give
                                                                            ? `storage/avatar/${item.give.id}/${item.give.image}`
                                                                            : 'images/incognita.jpg'
                                                                    "
                                                                />
                                                            </v-avatar>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-row no-gutters>
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-body-1"
                                                            >
                                                                <b>{{
                                                                    `${item.give.name} ${item.give.last_name}`
                                                                }}</b>
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption teal--text"
                                                            >
                                                                {{
                                                                    user.parroquia_id
                                                                        ? `${user.parroquia.canton.canton}, ${user.parroquia.canton.provincia.provincia},`
                                                                        : ""
                                                                }}
                                                                Ecuador
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption"
                                                            >
                                                                Miembro desde
                                                                {{
                                                                    since(
                                                                        user.created_at
                                                                    )
                                                                }}.
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption"
                                                            >
                                                                <b>Traby:</b>
                                                                {{
                                                                    item
                                                                        .traby_control
                                                                        .traby
                                                                        .title
                                                                }}
                                                                ({{
                                                                    since(
                                                                        item
                                                                            .traby_control
                                                                            .date_end
                                                                    )
                                                                }})
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption"
                                                            >
                                                                Puntuación:
                                                                {{
                                                                    item.evaluation
                                                                }}
                                                                <v-icon
                                                                    size="14"
                                                                    class="mt-n1"
                                                                    color="warning"
                                                                    >fas
                                                                    fa-star</v-icon
                                                                >
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="mt-1" />
                                                    <v-row
                                                        no-gutters
                                                        class="my-2"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-body-1"
                                                            >
                                                                {{
                                                                    `${item.observacion}`
                                                                }}
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider />
                                                </v-col>
                                            </v-row>
                                        </template>

                                        <v-row dense>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    <v-icon size="14"
                                                        >far
                                                        fa-check-circle</v-icon
                                                    >
                                                    <b
                                                        >Calificaciones como
                                                        Empleador:</b
                                                    >
                                                </p>
                                            </v-col>
                                            <v-col cols="auto">
                                                <p class="mb-0">
                                                    {{
                                                        user.qualifications_give
                                                            .length === 0
                                                            ? "No ha asignado Trabajos al dia."
                                                            : ""
                                                    }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <template
                                            v-if="
                                                user.qualifications_give
                                                    .length > 0
                                            "
                                        >
                                            <v-row
                                                v-for="(item,
                                                index) in user.qualifications_give"
                                                :key="index"
                                            >
                                                <v-col cols="auto">
                                                    <v-row no-gutters>
                                                        <v-col cols="12">
                                                            <v-avatar
                                                                size="70"
                                                                style="cursor:pointer"
                                                                @click="
                                                                    goProfile(
                                                                        item
                                                                            .recive
                                                                            .id
                                                                    )
                                                                "
                                                            >
                                                                <v-img
                                                                    :src="
                                                                        item.recive
                                                                            ? `storage/avatar/${item.recive.id}/${item.recive.image}`
                                                                            : 'images/incognita.jpg'
                                                                    "
                                                                />
                                                            </v-avatar>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-row no-gutters>
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-body-1"
                                                            >
                                                                <b>{{
                                                                    `${item.recive.name} ${item.recive.last_name}`
                                                                }}</b>
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption teal--text"
                                                            >
                                                                {{
                                                                    user.parroquia_id
                                                                        ? `${user.parroquia.canton.canton}, ${user.parroquia.canton.provincia.provincia},`
                                                                        : ""
                                                                }}
                                                                Ecuador
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption"
                                                            >
                                                                Miembro desde
                                                                {{
                                                                    since(
                                                                        user.created_at
                                                                    )
                                                                }}.
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption"
                                                            >
                                                                <b>Traby:</b>
                                                                {{
                                                                    item
                                                                        .traby_control
                                                                        .traby
                                                                        .title
                                                                }}
                                                                ({{
                                                                    since(
                                                                        item
                                                                            .traby_control
                                                                            .date_end
                                                                    )
                                                                }})
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        no-gutters
                                                        class="mt-n1"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-caption"
                                                            >
                                                                Puntuación:
                                                                {{
                                                                    item.evaluation
                                                                }}
                                                                <v-icon
                                                                    size="14"
                                                                    class="mt-n1"
                                                                    color="warning"
                                                                    >fas
                                                                    fa-star</v-icon
                                                                >
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="mt-1" />
                                                    <v-row
                                                        no-gutters
                                                        class="my-2"
                                                    >
                                                        <v-col cols="12">
                                                            <p
                                                                class="mb-0 text-body-1"
                                                            >
                                                                {{
                                                                    `${item.observacion}`
                                                                }}
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
moment.locale("es");

export default {
    data() {
        return {
            user: {
                last_name: null,
                name: null,
                courses: []
            },
            referencesPersonal: [],
            referencesWork: [],
            referencesTraby: [],
            typeView: 1
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        goProfile(id) {
            if (id !== 0) {
                let route = this.$router.resolve({
                    name: "Profile",
                    params: { id: id }
                });
                window.open(route.href, "_blank");

                return;
            }
        },
        since(time) {
            return moment(time).fromNow();
        },
        async getData() {
            try {
                const user = await axios.get(
                    `api/user/profile/${this.$route.params.id}`
                );

                if (user.data.references.length > 0) {
                    this.referencesPersonal = user.data.references.filter(
                        item => item.type === 1
                    );

                    this.referencesWork = user.data.references.filter(
                        item => item.type === 2
                    );
                }

                this.user = user.data;
            } catch (error) {
                console.error(error);
            }
        },
        sendWhatsapp() {
            window.open(
                "https://api.whatsapp.com/send?phone=593987116649&text=He%20visto%20tu%20perfil%20en%20Traby%20App,%20me%20intereza%20tus%20servicios",
                "_blank"
            );
        }
    },
    computed: {
        imageProfile() {
            if (this.user) {
                return this.user.image
                    ? `storage/avatar/${this.user.id}/${this.user.image}`
                    : "images/incognita.jpg";
            } else {
                return "images/incognita.jpg";
            }
        },
        level() {
            switch (Number(this.user.study.level)) {
                case 1:
                    return "Primaria";
                    break;
                case 2:
                    return "Secundaria";
                    break;
                case 3:
                    return `Tercer Nivel en ${this.user.study.career.description}`;
                    break;
                case 4:
                    return `Cuarto Nivel con base en ${this.user.study.career.description}`;
                    break;
                case 5:
                    return `Quinto Nivel con base en ${this.user.study.career.description}`;
                    break;
            }
        },
        aditionalStudies() {
            if (!this.user.study) {
                return false;
            }
            return this.user.study.level > 3;
        },
        qualificationStars() {
            const recive = this.user.stars_recive
                ? this.user.stars_recive.total
                : 0;
            const give = this.user.stars_give ? this.user.stars_give.total : 0;
            return Number(recive) + Number(give);
        }
    }
};
</script>
