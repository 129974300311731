<template>
  <div>
    <v-layout row wrap>
      <v-flex>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row>
            <v-flex>
              <v-text-field
                outlined
                :hide-details="!errors.email"
                label="CORREO"
                append-icon="fa fa-at"
                color="primary"
                background-color="inputBg"
                v-model="email"
                :error-messages="errors.email"
                :rules="rules.email"
                @keyup.enter="login"
                dense
                class="mb-2"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex>
              <v-text-field
                outlined
                :hide-details="!errors.password"
                label="CONTRASEÑA"
                append-icon="far fa-eye-slash"
                color="primary"
                background-color="inputBg"
                v-model="password"
                :rules="rules.password"
                type="password"
                :error-messages="errors.password"
                @keyup.enter="login"
                dense
                class="mb-2"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-alert
                prominent
                v-model="alertError"
                type="error"
                icon="far fa-times-circle"
              >Usuario o Contraseña incorrectos.</v-alert>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-alert
                v-model="alertSuccess"
                type="success"
                prominent
                color="teal"
                icon="far fa-check-circle"
              >Usterd se registro correctamente, porfavor verifique su correo.</v-alert>
            </v-flex>
          </v-layout>
          <v-layout row justify-space-around>
            <v-flex xs12 mb-2>
              <v-btn
                :loading="loadingButtons"
                small
                block
                outlined
                color="primary"
                @click="login"
              >Ingresar</v-btn>
            </v-flex>
            <v-flex xs12 class="d-flex justify-center">
              <a class="blue--text">¿Olvidaste tu contraseña?</a>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingButtons: false,
      showPassword: false,
      valid: true,
      email: null,
      alertError: false,
      alertSuccess: false,
      password: null,
      errors: [],
      rules: {
        email: [
          value => !!value || "El campo usuario es Requerido",
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) || "El campo no es considerado como email"
            );
          }
        ],
        password: [value => !!value || "El campo contraseña es Requerido"]
      }
    };
  },
  methods: {
    login() {
      this.loadingButtons = true;
      axios
        .post("api/login", {
          email: this.email,
          password: this.password
        })
        .then(response => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.access_token;
          localStorage.setItem("access_token", response.data.access_token);
          this.$router.push({ name: "Home" }).catch(err => {});
        })
        .catch(error => {
          switch (error.response.status) {
            case 401:
              this.alertError = true;
              this.alertSuccess = false;
              break;
            case 409:
              this.alertError = false;
              this.alertSuccess = true;
              break;
            case 422:
              this.errors = error.response.data.errors;
              this.alertSuccess = false;
              this.alertError = true;
              break;
            default:
              this.alertSuccess = false;
              this.alertError = true;
              break;
          }
          this.loadingButtons = false;
        });
    }
  }
};
</script>