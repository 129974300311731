<template>
  <div>
    <v-row dense justify="space-around">
      <v-col cols="3">
        <v-select
          :items="items"
          v-model="type"
          label="Tipo de Metrica"
          background-color="inputBg"
          item-text="description"
          item-value="id"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Rango de Fechas"
              readonly
              v-bind="attrs"
              v-on="on"
              background-color="inputBg"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range locale="es" :first-day-of-week="1">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row dense class="mb-2" justify="center">
      <v-col cols="auto">
        <v-btn outlined small color="primary" @click="fetchData">Buscar</v-btn>
      </v-col>
    </v-row>
    <div id="layout">
      <div id="container">
        <svg class="svg" />
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    dates: [],
    menu: false,
    type: 0,
    items: [
      {
        id: 0,
        description: "Adjudicados",
      },
      {
        id: 1,
        description: "Publicados",
      },
    ],
    data: [],
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (this.dates.length === 0) {
        this.dates = [
          moment().startOf("week").format("YYYY-MM-DD"),
          moment().endOf("week").format("YYYY-MM-DD"),
        ];
      }

      const data = await axios.get(
        `api/metrics/${this.dates[0]}/${this.dates[1]}/${this.type}`
      );

      this.data = data.data;

      this.$nextTick(() => {
        this.createdMetrics();
      });
    },
    createdMetrics() {
      const svg = d3.select("svg");
      svg.selectAll("*").remove();
      const svgContainer = d3.select("#container");

      const margin = 80;
      const width = 1000 - 2 * margin;
      const height = 600 - 2 * margin;

      const chart = svg
        .append("g")
        .attr("transform", `translate(${margin}, ${margin})`);

      const xScale = d3
        .scaleBand()
        .range([0, width])
        .domain(this.data.map((item) => item.date))
        .padding(0.4);

      const extractValue = this.data.map((item) => {
        return item.value;
      });

      const maxValue = Math.max(extractValue);

      const yScale = d3.scaleLinear().range([height, 0]).domain([0, maxValue]);

      const makeYLines = () => d3.axisLeft().scale(yScale);

      chart
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));

      chart.append("g").call(d3.axisLeft(yScale));

      chart
        .append("g")
        .attr("class", "grid")
        .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

      const barGroups = chart.selectAll().data(this.data).enter().append("g");

      barGroups
        .append("rect")
        .attr("class", "bar")
        .attr("x", (g) => xScale(g.date))
        .attr("y", (g) => yScale(g.value))
        .attr("height", (g) => height - yScale(g.value))
        .attr("width", xScale.bandwidth());

      barGroups
        .append("text")
        .attr("class", "value")
        .attr("x", (a) => xScale(a.date) + xScale.bandwidth() / 2)
        .attr("y", (a) => yScale(a.value) + 30)
        .attr("text-anchor", "middle")
        .text((a) => `$ ${a.value}`);

      svg
        .append("text")
        .attr("class", "title")
        .attr("x", width / 2 + margin)
        .attr("y", 40)
        .attr("text-anchor", "middle")
        .text(`Balance`);
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>

<style>
div#layout {
  text-align: center;
}

div#container {
  width: 1000px;
  height: 600px;
  margin: auto;
  background-color: #fff;
  border-radius: 25px;
}

.svg {
  width: 100%;
  height: 100%;
}

.bar {
  fill: #00a4cb;
}

.svg > g > g > text.value {
  fill: white;
}

text {
  font-size: 12px;
  fill: #000;
}

.grid path {
  stroke-width: 0;
}

.grid .tick line {
  stroke: #9faaae;
  stroke-opacity: 0.3;
}

text.value {
  font-size: 14px;
}

text.title {
  font-size: 22px;
  font-weight: 600;
}
</style>